import { ACTION_TYPES } from "../constants/action-types";
import _ from "lodash";

const initialState = {
  user: {},
  oAuth: {},
  producer: {},

  songs: [],
  songsPage: 0,
  totalSongsPages: 0,
  songsSorting: "createdDate,asc",
  genres: [],
  selectedGenre: null,
  selectedGenresPage: null,
  cart: [],
  loadingCart: [],
  favorites: [],
  loadingFavorites: [],
  latestSongs: [],
  popularSongs: [],

  searchTerm: null,
  searchType: null,

  sidebarOpen: false,
};

function rootReducer(state = initialState, action) {
  // if (action.type === ACTION_TYPES.ADD_ARTICLE) {
  //     return Object.assign({}, state, {
  //         articles: state.articles.concat(action.payload)
  //     });
  //     // var data = _.cloneDeep(resData);
  // }
  if (action.type === ACTION_TYPES.SET_PRODUCER) {
    return Object.assign({}, state, {
      producer: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SONGS) {
    return Object.assign({}, state, {
      songs: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SONGS_PAGE) {
    return Object.assign({}, state, {
      songsPage: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SONGS_PAGE) {
    return Object.assign({}, state, {
      songsPage: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_TOTAL_SONGS_PAGES) {
    return Object.assign({}, state, {
      totalSongsPages: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SONGS_SORTING) {
    return Object.assign({}, state, {
      songsSorting: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_GENRES) {
    return Object.assign({}, state, {
      genres: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SELECTED_GENRE) {
    return Object.assign({}, state, {
      selectedGenre: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SELECTED_GENRES_PAGE) {
    return Object.assign({}, state, {
      selectedGenresPage: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_CART) {
    return Object.assign({}, state, {
      cart: action.payload,
    });
  } else if (action.type === ACTION_TYPES.ADD_LOADING_CART) {
    return Object.assign({}, state, {
      loadingCart: state.loadingCart.concat(action.payload),
    });
  } else if (action.type === ACTION_TYPES.REMOVE_LOADING_CART) {
    return Object.assign({}, state, {
      loadingCart: state.loadingCart.filter((item) => item !== action.payload),
    });
  } else if (action.type === ACTION_TYPES.SET_FAVORITES) {
    return Object.assign({}, state, {
      favorites: action.payload,
    });
  } else if (action.type === ACTION_TYPES.ADD_LOADING_FAVORITES) {
    return Object.assign({}, state, {
      loadingFavorites: state.loadingFavorites.concat(action.payload),
    });
  } else if (action.type === ACTION_TYPES.REMOVE_LOADING_FAVORITES) {
    return Object.assign({}, state, {
      loadingFavorites: state.loadingFavorites.filter(
        (item) => item !== action.payload
      ),
    });
  } else if (action.type === ACTION_TYPES.SET_LATEST_SONGS) {
    return Object.assign({}, state, {
      latestSongs: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_POPULAR_SONGS) {
    return Object.assign({}, state, {
      popularSongs: action.payload,
    });
  } else if (action.type === ACTION_TYPES.SET_SEARCH_TERM) {
    return Object.assign({}, state, {
      searchTerm: !action.payload ? action.payload : action.payload.slice(0),
    });
  } else if (action.type === ACTION_TYPES.SET_SEARCH_TYPE) {
    return Object.assign({}, state, {
      searchType: !action.payload ? action.payload : action.payload.slice(0),
    });
  } else if (action.type === ACTION_TYPES.SET_SIDEBAR_OPEN) {
    return Object.assign({}, state, {
      sidebarOpen: action.payload,
    });
  }

  return state;
}

export default rootReducer;
