import React from "react";

import Login from "../Login/Login";
// import SignUp from '../SignUp/SignUp';
import { UserContext } from "../../auth/providers";
import { signOut, requestWithBearer } from "../../firebase/Firebase";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import {
  setSearchTerm,
  setSearchType,
  setSidebarOpen,
} from "../../redux/actions/actions";

const SEARCH_TYPES = {
  TITLE: "By Title",
  TAG: "By Tag",
};

class Header extends React.Component {
  state = {
    showLogin: false,
    showSignUp: false,
    isLoggedIn: false,
    searchInput: null,
    searchType: "TITLE",
  };

  // signUp = () => {
  //     if (this.state.showLogin || this.state.showSignUp)
  //         return;

  //     this.setState({ showSignUp: true });
  // }
  componentWillReceiveProps(newProps) {
    console.log("newProps");
    console.log(newProps);
    if (newProps.searchTerm !== this.props.searchTerm) {
      this.setState({ searchInput: newProps.searchTerm });
    }

    if (newProps.searchType !== this.props.searchType) {
      this.setState({ searchType: newProps.searchType });
    }
  }

  login = () => {
    if (this.state.showLogin || this.state.showSignUp) return;

    this.setState({ showLogin: true });
  };

  // getPhotoUrl = (user) => {
  //     var photoUrl = "../assets/images/users/thumb.jpg";
  //     if (user.photoURL != null)
  //         photoUrl = user.photoURL;
  //     if (user.providerData && user.providerData.length > 0) {
  //         if (user.providerData[0].providerId === "facebook.com") {
  //             if (localStorage.getItem('accessToken')) {
  //                 photoUrl += '?access_token=' + localStorage.getItem('accessToken');
  //             }
  //         }
  //     }

  //     return photoUrl;
  // }

  onInputChange = (event) => {
    if (event) event.preventDefault();
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  onFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!this.state.searchInput) {
      // alert("Invalid search term");
      return;
    }

    // this.props.setLoader(true);

    // window.searchTerm = this.state.searchInput;
    // window.useSearch = true;

    console.log(this.state.searchInput);
    this.props.setSearchTerm(this.state.searchInput);
    this.props.setSearchType(this.state.searchType);
    const {
      history: { push },
    } = this.props;
    push("/songs");

    // try {
    //     var searchResult = await requestWithBearer(`/tracks-service/api/tracks?sort=createdDate&term=${this.state.searchInput}`, {
    //         method: 'GET',
    //     });
    //     console.log(searchResult);
    // }
    // catch (error) {
    //     console.log(error);
    //     alert("Unable to search");
    // }

    // this.props.setLoader(false);
  };

  render() {
    let user = this.context.user;

    return (
      <>
        <header
          id="header"
          className="bg-primary"
          style={{ zIndex: 2, position: "absolute" }}
        >
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn toggle-menu mr-3"
              id="openSidebar"
              onClick={() => this.props.setSidebarOpen(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <form
              id="searchForm"
              style={{ display: "flex" }}
              onSubmit={this.onFormSubmit}
            >
              <div className="btn ion-ios-search"></div>
              <select
                name="searchType"
                id="searchType"
                value={this.state.searchType}
                onChange={this.onInputChange}
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  marginLeft: "1.75rem",
                }}
              >
                {Object.keys(SEARCH_TYPES).map((type) => (
                  <option
                    key={type}
                    style={{
                      backgroundColor: "#753fdc",
                      border: "none",
                    }}
                    value={type}
                  >
                    {SEARCH_TYPES[type]}
                  </option>
                ))}
                {/* <option style={{ backgroundColor: "#753fdc", border: "none" }} value="name">By Name</option>
                                <option style={{ backgroundColor: "#753fdc", border: "none" }} value="tag">By Tag</option> */}
              </select>
              <input
                type="text"
                placeholder="Search..."
                id="searchInput"
                name="searchInput"
                className="form-control"
                value={this.state.searchInput || ""}
                onChange={this.onInputChange}
              ></input>
            </form>

            <ul className="header-options d-flex align-items-center">
              {!user ? (
                <>
                  <li>
                    <a
                      className="btn btn-pill btn-air btn-sm btn-danger"
                      href="#"
                      data-toggle="modal"
                      data-target="#signIn"
                    >
                      Login
                    </a>
                  </li>
                  {/* <li><a href="#" data-toggle="modal" data-target="#signIn">Login</a></li>
                                        <li><a className="btn btn-pill btn-air btn-sm btn-danger" href="#"
                                            data-toggle="modal" data-target="#signUp">Signup</a></li> */}
                </>
              ) : (
                <li className="dropdown fade-in">
                  <a
                    href="#"
                    className="d-flex align-items-center py-2"
                    role="button"
                    id="userMenu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="pr-2">Hello {user.firstName}</span>
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        // src={this.getPhotoUrl(user)} alt="user" /></div>
                        src={
                          user.imageUrl
                            ? user.imageUrl
                            : "../assets/images/users/thumb.jpg"
                        }
                        alt="user"
                      />
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="userMenu"
                  >
                    <a className="dropdown-item">
                      <i className="ion-ios-heart"></i>
                      <span>
                        <Link to="/favorites"> My favorites</Link>
                      </span>
                    </a>
                    {/*<a className="dropdown-item" href="settings.html"><i className="ion-ios-settings"></i>*/}
                    {/*    <span>Settings</span></a>*/}
                    {/*<a className="dropdown-item" href="history.html"><i className="ion-ios-timer"></i>*/}
                    {/*    <span>History</span></a>*/}
                    {/* <a className="dropdown-item">
                                            <i className="ion-ios-musical-note"></i>
                                            <span>
                                                <Link to="/producer">
                                                    Be a Producer
                                                </Link>
                                            </span>
                                        </a> */}
                    <a className="dropdown-item">
                      <i className="ion-ios-cart"></i>
                      <span>
                        <Link to="/cart">My Cart</Link>
                      </span>
                    </a>
                    <a className="dropdown-item">
                      <i className="ion-ios-contact"></i>
                      <span>
                        <Link to="/profile">Profile</Link>
                      </span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <div className="p-2">
                      <a
                        className="btn btn-sm btn-block btn-air btn-pill btn-danger"
                        href="index.html"
                        onClick={signOut}
                      >
                        <i className="la la-sign-out"></i>
                        <span>Signout</span>
                      </a>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </header>
        <Login />
        {/* <SignUp /> */}
      </>
    );
  }
}

Header.contextType = UserContext;

// export default withRouter(Header);

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchTerm,
    searchType: state.searchType,
    sidebarOpen: state.sidebarOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchTerm: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
    setSearchType: (searchType) => dispatch(setSearchType(searchType)),
    setSidebarOpen: (sidebarOpen) => dispatch(setSidebarOpen(sidebarOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
