import React from "react";
import { UserContext } from "../../auth/providers";
// import { isMobileOnly } from 'react-device-detect';

// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import Home from "../Home/Home";
import Header from "../Header/Header";
import Profile from "../Profile/Profile";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import MyFavorites from "../MyFavorites/MyFavorites";
import BeProducer from "../BeProducer/BeProducer";
import Genres from "../Genres/Genres";
import MyTracks from "../MyTracks/MyTracks";
import Songs from "../Songs/Songs";
import MyCart from "../MyCart/MyCart";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyPurchases from "../MyPurchases/MyPurchases";
import Waveform from "../Waveform/Waveform";
import ContactUs from "../ContactUs/ContactUs";
import { connect } from "react-redux";
import { fetchCart, fetchFavorites } from "../../api/TracksApiController";
import PrivacyPolicy from "../TnC/PrivacyPolicy";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51F7RsaApuEeVujPHBDAOzQViS4W5RmoRV2AxqzJbwVRPher3zdAMFMInxiWtFyAZRPrgc1xhKOywX3p6YDDEeEnH00FITwMpum"
);

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      loaderText: "Loading",
      cartCount: 0,
      playingSong: {},
      isPlayerVisibile: false,
    };
    //   this.userClaims = null;
  }

  componentDidMount() {
    // if(isMobileOnly){
    //   window.location.href= '/issue/index.html';
    // }

    if (this.context.user) {
      fetchFavorites();
      fetchCart();
    }

    this.setLoader(false);
  }

  componentWillReceiveProps = (newProps) => {
    if (this.context.user) {
      fetchFavorites();
      fetchCart();
    }
  };

  setLoader = (newVal, loaderText = "Loading") => {
    this.setState({ showLoader: newVal, loaderText });
  };

  incrementCartCount = () => {
    this.setState({ cartCount: this.state.cartCount + 1 });
  };

  decrementCartCount = () => {
    if (this.state.cartCount > 0)
      this.setState({ cartCount: this.state.cartCount - 1 });
  };

  setCartCount = (cartCount) => {
    this.setState({ cartCount });
  };

  playSong = (song) => {
    var playingSong = {
      name: song.title,
      description: song.description,
      musicSrc: song.audioFile, //"/assets/audio/ringtone-5.mp3",
      cover: song.imageUrl,
    };
    this.setState({ playingSong });
  };

  setPlayerVisibility = (isVisible) => {
    this.setState({ isPlayerVisibile: isVisible });
  };

  render() {
    let user = this.context.user;

    return (
      <>
        <Loader
          show={this.state.showLoader}
          loaderText={this.state.loaderText}
        ></Loader>
        <div
          id="wrapper"
          data-scrollable="true"
          className={this.props.sidebarOpen ? "open-sidebar" : ""}
        >
          <Sidebar cartCount={this.state.cartCount} />

          <main id="pageWrapper" style={{ paddingBottom: "1rem" }}>
            <Header setLoader={this.setLoader} />

            {/* <div className="banner bg-home"></div>

                        <div className="main-container" id="appRoute"> */}
            <Switch>
              <Route path="/myTracks">
                <MyTracks
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                />
              </Route>
              <Route path="/profile">
                <Profile
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                />
              </Route>
              <Route path="/cart">
                <Elements stripe={stripePromise}>
                  <MyCart
                    setLoader={this.setLoader}
                    playSong={this.playSong}
                    setPlayerVisibility={this.setPlayerVisibility}
                    incrementCartCount={this.incrementCartCount}
                    decrementCartCount={this.decrementCartCount}
                    setCartCount={this.setCartCount}
                  />
                </Elements>
              </Route>
              <Route path="/favorites">
                <MyFavorites
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                  incrementCartCount={this.incrementCartCount}
                  decrementCartCount={this.decrementCartCount}
                  setCartCount={this.setCartCount}
                />
              </Route>
              <Route path="/producer">
                <BeProducer
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                />
              </Route>
              <Route path="/genres">
                <Genres
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                  incrementCartCount={this.incrementCartCount}
                  decrementCartCount={this.decrementCartCount}
                  setCartCount={this.setCartCount}
                />
              </Route>
              <Route path="/songs">
                <Songs
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                  incrementCartCount={this.incrementCartCount}
                  decrementCartCount={this.decrementCartCount}
                  setCartCount={this.setCartCount}
                />
              </Route>
              <Route path="/purchases">
                <MyPurchases
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                  incrementCartCount={this.incrementCartCount}
                  decrementCartCount={this.decrementCartCount}
                  setCartCount={this.setCartCount}
                />
              </Route>
              <Route path="/contact">
                <ContactUs setPlayerVisibility={this.setPlayerVisibility} />
              </Route>
              <Route path="/policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/home">
                <Home
                  setLoader={this.setLoader}
                  playSong={this.playSong}
                  setPlayerVisibility={this.setPlayerVisibility}
                />
              </Route>
              <Redirect from="/" to="/home" />
            </Switch>
            {/* </div> */}
          </main>
        </div>

        <Waveform
          song={this.state.playingSong}
          visible={this.state.isPlayerVisibile}
        />
      </>
    );
  }
}

Application.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    sidebarOpen: state.sidebarOpen,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setSidebarOpen: (sidebarOpen) => dispatch(setSidebarOpen(sidebarOpen)),
//   };
// };

export default connect(mapStateToProps, null)(Application);
