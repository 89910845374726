import React, { Component } from "react";
import UserContext from "./UserContext";
import { auth, requestWithBearer } from "../../firebase/Firebase";

export default class UserProvider extends Component {
    state = {
        user: null,
        oAuth: null,
        // profileUpdated: false,
    };

    componentDidMount = async () => {
        auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                console.log(userAuth);
                console.log(userAuth.email, userAuth.photoURL);
                var id = await auth.currentUser.getIdToken(true);
                console.log(id);

                auth.currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        // Check and add the user role
                        console.log(idTokenResult);
                        console.log(idTokenResult.claims.roles);

                        if (idTokenResult.claims != null && idTokenResult.claims.roles) {
                            // Show admin UI.
                            //showAdminUI();
                            console.log(idTokenResult.roles);
                            this.setState(prevState => ({
                                user: {
                                    ...prevState.user,
                                    role: idTokenResult.claims.roles,
                                }
                            }));
                        } else {
                            // Show regular user UI.
                            console.log("No name found");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                try {
                    await this.getUserProfile();
                }
                catch (error) {
                    console.log(error);
                    if (error.response && error.response.status === 404) {
                        console.log("Making new profile");
                        this.setState(prevState => ({
                            user: {
                                ...prevState.user,
                                firstName: userAuth.displayName,
                                // email: userAuth.email,
                            },
                            oAuth: userAuth,
                        }));

                        if (window.location.pathname !== '/profile')
                            window.location.href = "/profile";

                        // this.props.history.push("/profile");
                        // this.history.pushState(null, 'profile');
                    }
                }

                // if (!this.state.user || !this.state.user.lastName) {
                //     if (window.location.pathname !== '/profile')
                //         window.location.href = "/profile";
                // }
            }
            else {
                localStorage.clear();
                sessionStorage.clear();
                window.userData = null
                this.setState({
                    user: null
                })
            }
        });
    };

    getUserProfile = async () => {
        var userProfile = await requestWithBearer('/user-service/api/user/profile', {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
        });

        console.log(userProfile.data);

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                ...userProfile.data,
            },
        }));
    }

    render() {
        // const { user } = this.state;
        const providerValue = {
            user: this.state.user,
            oAuth: this.state.oAuth,
            getUserProfile: this.getUserProfile,
        };

        return (
            <UserContext.Provider value={providerValue}>
                { this.props.children}
            </UserContext.Provider >
        );
    }
}