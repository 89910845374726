import React from "react";
import Slider from "react-slick";
import { requestWithoutBearer } from "../../firebase/Firebase";
import GenreSongs from "./GenreSongs";
import { fetchGenres } from "../../api/TracksApiController";

import { connect } from "react-redux";
import { setGenres, setSelectedGenre } from "../../redux/actions/actions";

class Genres extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setPlayerVisibility(true);
        fetchGenres();
    }

    componentWillUnmount() {
        this.props.setSelectedGenre(null);
    }

    // getGenres = async () => {
    //     try {
    //         var uploadResponse = await requestWithoutBearer('/tracks-service/api/genres', {
    //             method: 'get',
    //         });
    //         console.log(uploadResponse.data);

    //         if (uploadResponse.data.length > 0) {
    //             // this.setState({ genres: uploadResponse.data });
    //             this.props.setGenres(uploadResponse.data);
    //         }
    //     }
    //     catch (error) {
    //         console.log(error);
    //         alert("Unable to get genres");
    //     }
    // }

    render() {
        return (
            <>
                {this.props.selectedGenre == null ||
                this.props.selectedGenre.id == null ? (
                    <>
                        <div className="banner bg-genre"></div>
                        <div className="main-container" id="appRoute">
                            <div className="section row">
                                {this.props.genres &&
                                    this.props.genres.map((genre) => (
                                        <div
                                            className="col-xl-4 col-lg-4 col-sm-6 pb-4"
                                            key={genre.id}
                                        >
                                            <div className="custom-card">
                                                <div className="custom-card--img">
                                                    <a href="#">
                                                        <img
                                                            className="card-img--radius-md"
                                                            alt={genre.title}
                                                            src={
                                                                !genre.imageUrl
                                                                    ? "/assets/images/background/horizontal/1.jpg"
                                                                    : genre.imageUrl
                                                            }
                                                            onClick={() =>
                                                                this.props.setSelectedGenre(
                                                                    genre
                                                                )
                                                            }
                                                        />
                                                        {/* <span className="bg-blur">{genre.title}</span> */}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <GenreSongs
                        genre={this.props.selectedGenre}
                        onBack={() => this.props.setSelectedGenre(null)}
                        incrementCartCount={this.props.incrementCartCount}
                        decrementCartCount={this.props.decrementCartCount}
                        setCartCount={this.props.setCartCount}
                        playSong={this.props.playSong}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        genres: state.genres,
        selectedGenre: state.selectedGenre,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setGenres: (genres) => dispatch(setGenres(genres)),
        setSelectedGenre: (selectedGenre) =>
            dispatch(setSelectedGenre(selectedGenre)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Genres);
