import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import { setSubState, retryCall } from "../../common/common";
// import ReactJkMusicPlayer from 'react-jinke-music-player';
// import 'react-jinke-music-player/assets/index.css';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Waveform from "../Waveform/Waveform";

const FileDownload = require("js-file-download");

export default class MyPurchases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchasedSongs: [],
      // favoritesInfo: {},
      cart: [],
      // playingSong: {},
    };

    // this.audioInstance = null
  }

  componentDidMount() {
    this.props.setPlayerVisibility(true);
    // Only fetch once when website is openend instead of everytime genre is loaded
    // retryCall(this.getPurchases, 3, 2000);
    this.getPurchases();
  }

  // onInputChange = event => {
  //     // if (event)
  //     //     event.preventDefault();
  //     let value = event.target.value
  //     // this.setState({ [event.target.name]: value });
  //     this.setSubState('addTrack', { [event.target.name]: value });
  // };

  getPurchases = async () => {
    this.props.setLoader(true);

    try {
      var response = await requestWithBearer(`/tracks-service/api/order`, {
        method: "get",
        headers: { "Content-Type": "application/json" },
        // body: newTrackData,
      });

      var requestData = response.data;
      console.log(requestData);

      let purchasedSongs = [];
      if (requestData && requestData.content) {
        var myOrders = requestData.content;
        for (var i = 0; i < myOrders.length; i++) {
          if (
            myOrders[i].status.toUpperCase() === "PROCESSED" &&
            myOrders[i].items
          ) {
            purchasedSongs = [...purchasedSongs, ...myOrders[i].items];
          }
        }
      }

      this.setState({ purchasedSongs });

      // var data = {};
      // if (requestData) {
      //     data.favoritesInfo = requestData;
      //     if (requestData.items)
      //         data.favorites = [...requestData.items];
      // }
      // this.setState(data);

      this.props.setLoader(false);
      return true;
    } catch (error) {
      console.log("Unable to get purchases");
      console.log(error);
      this.props.setLoader(false);
      return false;
    }
  };

  downloadAsset = async (song) => {
    this.props.setLoader(true);

    try {
      console.log(song.id);
      var uploadResponse = await requestWithBearer(
        // `/tracks-service/api/track/${song.id}/downloadAsset`,
        `/zip/${song.id}`,
        {
          method: "GET",
        },
        {
          responseType: "blob",
        }
      );

      var fileName = "tracks.zip";
      // if (song.assertUrl) {
      //   var assetPathSplit = song.assertUrl.split("/");
      //   fileName = assetPathSplit[assetPathSplit.length - 1];
      // }

      console.log(uploadResponse);
      if (!uploadResponse) throw "Unable to download file";

      FileDownload(uploadResponse.data, fileName);

      // console.log(uploadResponse);

      // const url = window.URL.createObjectURL(new Blob([uploadResponse.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', fileName);
      // document.body.appendChild(link);
      // link.click();

      alert("Your assset will download shortly");
    } catch (error) {
      console.log(error);
      alert("Unable to download");
    }

    this.props.setLoader(false);
  };

  // playSong = (song) => {
  //     console.log(song);
  //     // this.audioInstance.clear();
  //     var playingSong = {
  //         name: song.title,
  //         musicSrc: song.audioFile, //"/assets/audio/ringtone-5.mp3",
  //         cover: song.imageUrl,
  //     };
  //     this.setState({ playingSong });
  // };

  render() {
    let user = this.context.user;

    if (!user) return null;

    return (
      <>
        <div className="banner bg-purchases"></div>

        <div className="main-container" id="appRoute">
          <div className="row align-items-end">
            {/* <span className="col-6 font-weight-bold">
                            {this.state.favoritesInfo.totalElements} Results
                        </span> */}
            <div className="col-6 ml-auto">
              <form
                action="#"
                className="form-inline justify-content-end invisible"
              >
                <label className="mr-2" htmlFor="filter2">
                  Filter By:
                </label>
                <select className="custom-select mr-sm-2" id="filter2">
                  <option selected>Popular</option>
                  <option value="1">A-Z</option>
                  <option value="2">Z-A</option>
                </select>
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="section custom-list">
            {this.state.purchasedSongs.map((song) => (
              <div className="custom-list--item flex-nowrap">
                <div
                  className="text-dark custom-card--inline"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.playSong(song);
                  }}
                >
                  <div className="custom-card--inline-img">
                    <img
                      src={song.imageUrl}
                      alt=""
                      className="card-img--radius-sm"
                    />
                  </div>

                  <div className="custom-card--inline-desc">
                    <p className="text-truncate mb-0">{song.title}</p>
                    <p className="text-truncate text-muted font-sm mb-0">
                      {song.description}
                    </p>
                  </div>
                </div>
                <ul className="custom-card--labels d-flex ml-auto">
                  <li>
                    <a
                      className="badge badge-pill badge-dark"
                      onClick={() => this.downloadAsset(song)}
                      style={{
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      <i className="la la-download"></i>
                    </a>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>

        <NotificationContainer />

        {/* <Waveform song={this.state.playingSong} /> */}
      </>
    );
  }
}

MyPurchases.contextType = UserContext;
