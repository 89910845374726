import React from "react";
import { UserContext } from "../../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../../firebase/Firebase";
import { setSubState, retryCall } from "../../../common/common";
import ReactJkMusicPlayer from "react-jinke-music-player";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  addToCart,
  addToFavorite,
  fetchCart,
  fetchFavorites,
  fetchLatestSongs,
  removeFromCart,
  removeFromFavorite,
} from "../../../api/TracksApiController";
import { setCart, setFavorites } from "../../../redux/actions/actions";
import { connect } from "react-redux";

class Latest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // songs: [],
      // songsInfo: {},
      // favorites: [],
      // cart: [],
      selectedTrack: null,
      showEditTrack: false,
      // playingSong: {},
      // showToast: false,
    };

    this.audioInstance = null;
  }

  componentDidMount() {
    // Only fetch once when website is openend instead of everytime genre is loaded
    // this.getAllTracks();
    fetchLatestSongs();
    // if (this.context.user) {
    //   // retryCall(this.getFavorites, 3, 2000);
    //   // retryCall(this.getCartItems, 3, 2000);
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

  // getAllTracks = async () => {
  //   // this.props.setLoader(true);
  //   try {
  //     var response = await requestWithoutBearer(
  //       `/tracks-service/api/tracks?page=0&size=20&sort=title,asc`,
  //       {
  //         method: "get",
  //         headers: { "Content-Type": "application/json" },
  //         // body: newTrackData,
  //       }
  //     );

  //     var requestData = response.data;
  //     console.log(requestData);

  //     var data = {};
  //     if (requestData) {
  //       data.songsInfo = requestData;
  //       if (requestData.content) data.songs = [...requestData.content];
  //     }
  //     this.setState(data);
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Unable to get all tracks");
  //   }
  //   // await fetchSongs(
  //   //     {
  //   //         sortBy: "title,asc",
  //   //     },
  //   //     forceFetch
  //   // );
  //   // this.props.setLoader(false);
  // };

  // getFavorites = async () => {
  //   try {
  //     var response = await requestWithBearer(`/tracks-service/api/favourties`, {
  //       method: "get",
  //       headers: { "Content-Type": "application/json" },
  //       // body: newTrackData,
  //     });

  //     var requestData = response.data;
  //     console.log(requestData);

  //     if (requestData.items) this.setState({ favorites: requestData.items });
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Unable to get favorites");
  //   }
  // };

  // getCartItems = async () => {
  //   try {
  //     var response = await requestWithBearer(`/tracks-service/api/cart`, {
  //       method: "get",
  //       headers: { "Content-Type": "application/json" },
  //       // body: newTrackData,
  //     });

  //     var requestData = response.data;
  //     console.log(requestData);

  //     if (requestData.items) {
  //       this.setState({ cart: requestData.items });
  //       this.props.setCartCount(requestData.items.length);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Unable to get cart items");
  //   }
  // };

  favoriteSong = async (track) => {
    // this.props.setLoader(true);
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isFavorited(track)) {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/favourties/track/${track.id}`,
      //     {
      //       method: "post",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // alert("Song favorited!");
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to favorite track");
      // }
      addToFavorite(track);
    } else {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/favourties/track/${track.id}`,
      //     {
      //       method: "delete",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // alert("Song unfavorited!");
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to unfavorite track");
      // }
      removeFromFavorite(track);
    }

    // await this.getFavorites();
    await fetchFavorites(true);

    // this.props.setLoader(false);
  };

  addToCartSong = async (track) => {
    // this.props.setLoader(true);
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isInCart(track)) {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/cart/track/${track.id}`,
      //     {
      //       method: "post",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // this.setState({showToast: true});
      //   NotificationManager.success("Song added to cart");
      //   this.props.incrementCartCount();
      //   // alert("Song added to cart!");
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to add song to cart");
      // }
      addToCart(track);
    } else {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/cart/track/${track.id}`,
      //     {
      //       method: "delete",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   this.props.decrementCartCount();
      //   // alert("Song removed from cart!");
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to remove song from cart");
      // }
      removeFromCart(track);
    }

    // await this.getCartItems();
    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isFavorited = (song) => {
    if (!this.props.favorites) return false;

    for (var i = 0; i < this.props.favorites.length; i++) {
      if (this.props.favorites[i].id === song.id) return true;
    }

    return false;
  };

  isInCart = (song) => {
    if (!this.props.cart) return false;

    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].id === song.id) return true;
    }

    return false;
  };

  // playSong = (song) => {
  //     // this.audioInstance.clear();
  //     var playingSong = {
  //         name: song.title,
  //         musicSrc: song.audioFile,   //"/assets/audio/ringtone-5.mp3",
  //         cover: song.imageUrl,
  //     };
  //     this.setState({ playingSong });

  //     this.props.playSong(playingSong);
  // }

  render() {
    return (
      <div className="section">
        <div className="heading">
          <div className="d-flex flex-wrap align-items-end">
            <div className="flex-grow-1">
              <h4>Fresh Out Of The Oven</h4>
              <p>Listen to latest tracks</p>
            </div>
            {/* <a href="songs.html" className="btn btn-sm btn-pill btn-air btn-primary">View All</a> */}
          </div>
          <hr />
        </div>

        <div className="custom-list mb-5">
          {this.props.latestSongs.map((song) => (
            <div className="custom-list--item flex-nowrap">
              <div
                className="text-dark custom-card--inline"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.playSong(song);
                }}
              >
                <div className="custom-card--inline-img">
                  <img
                    src={song.imageUrl}
                    alt=""
                    className="card-img--radius-sm"
                  />
                </div>

                <div className="custom-card--inline-desc">
                  <p className="text-truncate mb-0">{song.title}</p>
                  <p className="text-truncate text-muted font-sm mb-0">
                    {song.description}
                  </p>
                </div>
              </div>
              <ul className="custom-card--labels d-flex ml-auto">
                <li>
                  <a
                    className="badge badge-pill badge-danger"
                    onClick={() => this.favoriteSong(song)}
                    style={{
                      border: "solid 1px red",
                      color: this.isFavorited(song) ? "white" : "red",
                      backgroundColor: this.isFavorited(song) ? "red" : "white",
                    }}
                  >
                    <i className="la la-heart"></i>
                  </a>
                </li>
                <li>
                  <a
                    className={`badge badge-pill ${
                      this.isInCart(song) ? "badge-light" : "badge-success"
                    }`}
                    onClick={() => this.addToCartSong(song)}
                    style={{
                      color: this.isInCart(song) ? "black" : "white",
                      padding: "0 0.5rem",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "5.75rem",
                      cursor: "pointer",
                    }}
                  >
                    {/* <i className="la-shopping-cart"></i> */}
                    <i className="ion-ios-cart"></i>&nbsp;
                    {!this.isInCart(song) ? "Add To Cart" : "In Cart"}
                  </a>
                </li>
                {/* <li>05:03</li> */}
                {/* <li className="dropleft">
                                            <a href="#" className="btn btn-icon-only btn-secondary p-0 w-auto h-auto"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="la la-ellipsis-h"></i>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link favorite">
                                                        <i className="la la-heart-o"></i>
                                                        <span>Favorite</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-plus"></i>
                                                        <span>Add to Playlist</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-download"></i>
                                                        <span>Download</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-share-alt"></i>
                                                        <span>Share</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="song-details.html" className="dropdown-link">
                                                        <i className="la la-info-circle"></i>
                                                        <span>Song Info</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li> */}
              </ul>
            </div>
          ))}
          <div style={{ height: "4rem" }}></div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

// export default Latest;

Latest.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    favorites: state.favorites,
    latestSongs: state.latestSongs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setCart: (cart) => dispatch(setCart(cart)),
    // setFavorites: (favorites) => dispatch(setFavorites(favorites)),
  };
};

export default connect(mapStateToProps, null)(Latest);
