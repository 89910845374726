import { requestWithoutBearer, requestWithBearer } from "../firebase/Firebase";

export const DEFAULT_PAGE_SIZE = 20;

export const getTracks = async ({
  status,
  genreId,
  sortBy,
  searchTerm,
  searchType,
  pageNumber = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  paged = true,
} = {}) => {
  try {
    // /tracks-service/api/tracks?status=APPROVAL_PENDING&genreId=2&term=a&termType=TAG&sort[sorted]=true&sort[unsorted]=true&sort[empty]=true&pageNumber=0&pageSize=0&paged=true&unpaged=true&offset=0
    let paramsList = [];
    if (status) paramsList.push("status=" + status);
    if (genreId) paramsList.push("genreId=" + genreId);
    if (searchTerm) paramsList.push("term=" + searchTerm);
    if (searchTerm && searchType) paramsList.push("termType=" + searchType);
    if (sortBy) paramsList.push("sort=" + sortBy);
    // if (paged) paramsList.push("paged=" + paged);
    if (paged && pageSize != null) paramsList.push("size=" + pageSize);
    if (paged && pageNumber != null) {
      paramsList.push("page=" + pageNumber);
      // paramsList.push("offset=" + pageNumber * DEFAULT_PAGE_SIZE);
    }

    let paramsStr = paramsList.join("&");
    let response = await requestWithoutBearer(
      `/tracks-service/api/tracks${paramsStr !== "" ? "?" + paramsStr : ""}`,
      {
        method: "GET",
      }
    );

    if (!response.data) throw "no genre data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get all tracks" };
  }
};

export const getGenres = async () => {
  try {
    var response = await requestWithoutBearer("/tracks-service/api/genres", {
      method: "GET",
    });

    if (!response.data) throw "No genre data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get genres" };
  }
};

export const getFavorites = async () => {
  try {
    var response = await requestWithBearer(`/tracks-service/api/favourties`, {
      method: "GET",
    });

    if (!response.data) throw "No favorite data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get favorites" };
  }
};

export const postFavorite = async (songId) => {
  try {
    var response = await requestWithBearer(
      `/tracks-service/api/favourties/track/${songId}`,
      {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.data) throw "No favorite data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to add to favorites" };
  }
};

export const deleteFavorite = async (songId) => {
  try {
    var response = await requestWithBearer(
      `/tracks-service/api/favourties/track/${songId}`,
      {
        method: "DELETE",
      }
    );

    if (!response.data) throw "No favorite data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to remove from favorites" };
  }
};

export const getCart = async () => {
  try {
    var response = await requestWithBearer(`/tracks-service/api/cart`, {
      method: "GET",
    });

    if (!response.data) throw "No cart data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get cart" };
  }
};

export const postCart = async (songId) => {
  try {
    var response = await requestWithBearer(
      `/tracks-service/api/cart/track/${songId}`,
      {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.data) throw "No cart data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to add to cart" };
  }
};

export const deleteCart = async (songId) => {
  try {
    var response = await requestWithBearer(
      `/tracks-service/api/cart/track/${songId}`,
      {
        method: "DELETE",
      }
    );

    if (!response.data) throw "No cart data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to remove from cart" };
  }
};

export const getPopularSongs = async () => {
  try {
    var response = await requestWithoutBearer(
      "/tracks-service/api/tracks/hotPick",
      {
        method: "GET",
      }
    );

    if (!response.data) throw "No hot picks data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get hot picks" };
  }
};

export const getLatestSongs = async () => {
  try {
    var response = await requestWithoutBearer(
      `/tracks-service/api/tracks?page=0&size=20&sort=title,asc`,
      {
        method: "GET",
      }
    );

    if (!response.data) throw "No latest data";
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw { message: "Unable to get latest songs" };
  }
};
