import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
  requestWithBearerAndProgress,
} from "../../firebase/Firebase";
import { setSubState, retryCall } from "../../common/common";
import Swal from "sweetalert2";

export default class BeProducer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.setPlayerVisibility(false);
    // Only fetch once when website is openend instead of everytime genre is loaded
    retryCall(this.getProducerRequest, 3, 2000);
  }

  onInputChange = (event) => {
    // if (event)
    //     event.preventDefault();
    let value = event.target.value;
    this.setState({ [event.target.name]: value });

    // this.setState(prevState => ({
    //     requestStatus: {
    //         ...prevState.requestStatus,
    //         [event.target.name]: value
    //     }
    // }));
  };

  getProducerRequest = async () => {
    // this.props.setLoader(true);
    try {
      var response = await requestWithBearer("/user-service/api/user/request", {
        method: "get",
      });
      console.log(response.data);

      if (!!response.data) {
        var newState = { ...response.data };
        this.setState(newState);
      }

    //   this.props.setLoader(false);
      return true;
    } catch (error) {
      console.log("Unable to get request");
      console.log(error);
    //   this.props.setLoader(false);
      return false;
    }
  };

  onLoadFile = (event) => {
    if (!event.target.files[0])
      // cancel clicked
      return;

    let value = event.target.value;
    let file = event.target.files[0];
    this.setState({
      [event.target.name]: file,
      // [event.target.name + '_File']: file
    });
    // console.log(file);
  };

  onFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!this.state.title) {
      alert("Invalid Display Name");
      return;
    }
    if (!this.state.track1 || !this.state.track1.size) {
      alert("Invalid Track 1");
      return;
    }
    if (!this.state.track2 || !this.state.track2.size) {
      alert("Invalid Track 2");
      return;
    }
    if (!this.state.license || !this.state.license.size) {
      alert("Invalid License");
      return;
    }

    this.props.setLoader(true);

    var data = new FormData();
    data.append(
      "request",
      JSON.stringify({
        title: this.state.title,
        track1: this.state.track1.name,
        track2: this.state.track2.name,
        license: this.state.license.name,
      })
    );
    data.append("track1", this.state.track1);
    data.append("track2", this.state.track2);
    data.append("license", this.state.license);

    try {
      var response = await requestWithBearerAndProgress(
        "/user-service/api/user/request",
        {
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          body: data,
        }
      );
      console.log(response);

      // await this.getProducerRequest();
      alert("Your request to be a producer has been submitted for approval.");
    } catch (error) {
      console.log(error);
      alert("Unable to send producer request");
    }

    this.props.setLoader(false);
  };

  render() {
    let user = this.context.user;

    if (!user) return null;

    if (!user.lastName) {
      Swal.fire({
        title: "Profile Incomplete",
        text: "You need to complete your profile before submitting request to become a producer.",
        icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#753fdc",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Edit Profile",
      }).then((result) => {
        // if (result.isConfirmed) {
        //   Swal.fire("Deleted!", "Your file has been deleted.", "success");
        // }
        if (window.location.pathname !== "/profile")
          window.location.href = "/profile";
      });
    }

    return (
      <>
        <div className="banner bg-producer"></div>

        <div className="main-container" id="appRoute">
          <div className="row section">
            <div className="col-xl-8 col-md-10 mx-auto">
              <form className="card" onSubmit={this.onFormSubmit}>
                <div className="card-header">
                  <h6 className="card-title mb-0">Be A Producer</h6>
                  {this.state.status && (
                    <p>
                      {this.state.status === "PENDING" &&
                        "Your request is pending review. You can still re-upload files to modify request."}
                      {this.state.status === "APPROVED" &&
                        "Your request is approved. You are now registered as a producer."}
                      {this.state.status === "REJECTED" &&
                        "Your request is rejected. You can upload new files to send request again."}
                    </p>
                  )}
                </div>

                {this.state.status !== "APPROVED" && user.lastName && (
                  <>
                    <div className="card-body">
                      <div action="#">
                        <div className="form-row form-group">
                          <label
                            for="title"
                            className="col-md-4 text-md-right col-form-label"
                          >
                            Display Name
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              id="title"
                              name="title"
                              className="form-control"
                              required
                              value={this.state.title}
                              onChange={this.onInputChange}
                            />
                          </div>
                        </div>
                        <div className="form-row form-group">
                          <label
                            for="track1"
                            className="col-md-4 text-md-right col-form-label"
                          >
                            Sample Track 1
                          </label>
                          <div className="col-md-7">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="track1"
                                name="track1"
                                accept="audio/*"
                                required
                                onChange={this.onLoadFile}
                              />
                              <label className="custom-file-label" for="track1">
                                {!this.state.track1 || !this.state.track1.name
                                  ? "Choose file"
                                  : this.state.track1.name}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-row form-group">
                          <label
                            for="track2"
                            className="col-md-4 text-md-right col-form-label"
                          >
                            Sample Track 2
                          </label>
                          <div className="col-md-7">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="track2"
                                name="track2"
                                accept="audio/*"
                                required
                                onChange={this.onLoadFile}
                              />
                              <label
                                className="custom-file-label"
                                for="songImage"
                              >
                                {!this.state.track2 || !this.state.track2.name
                                  ? "Choose file"
                                  : this.state.track2.name}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-row form-group">
                          <label
                            for="license"
                            className="col-md-4 text-md-right col-form-label"
                          >
                            Driver's License
                          </label>
                          <div className="col-md-7">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="license"
                                name="license"
                                accept="image/*"
                                required
                                onChange={this.onLoadFile}
                              />
                              <label
                                className="custom-file-label"
                                for="songImage"
                              >
                                {!this.state.license || !this.state.license.name
                                  ? "Choose file"
                                  : this.state.license.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-7">
                          <button
                            type="submit"
                            className="btn btn-brand btn-air"
                          >
                            Send Request
                          </button>
                          {/* <button type="button" className="btn btn-outline-secondary">Clear Form</button> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

BeProducer.contextType = UserContext;
