import React from "react";
import { fetchCart, fetchFavorites } from "../../api/TracksApiController";
import { UserContext } from "../../auth/providers";
import Waveform from "../Waveform/Waveform";
import GenresMini from "./GenresMini/GenresMini";
import Latest from "./Latest/Latest";
import Popular from "./Popular/Popular";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playingSong: {},
    };
  }

  componentDidMount() {
    this.props.setPlayerVisibility(true);
    // if (this.context.user) {
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

//   componentWillReceiveProps = (newProps) => {
//     if (this.context.user) {
//       fetchFavorites();
//       fetchCart();
//     }
//   };

  playSong = (song) => {
    // console.log(song.audioFile);
    // this.audioInstance.clear();
    var playingSong = {
      name: song.title,
      description: song.description,
      musicSrc: song.audioFile, //"/assets/audio/ringtone-5.mp3",
      cover: song.imageUrl,
    };
    this.setState({ playingSong });
  };

  render() {
    return (
      <>
        <div className="banner bg-home"></div>

        <div className="main-container" id="appRoute">
          <GenresMini />
          <Latest playSong={this.props.playSong} />
          <Popular playSong={this.props.playSong} />

          {/* <Waveform song={this.state.playingSong} /> */}
        </div>
      </>
    );
  }
}

Home.contextType = UserContext;

export default Home;
