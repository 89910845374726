import React from "react";
import { UserContext } from "../../auth/providers";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { requestWithBearer } from "../../firebase/Firebase";
import "./Profile.css";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      gender: null,
      country: null,
      city: null,
      zipcode: null,
      data: {},
      isModified: false,
      paypalId: null,
      showTnC: false,
    };
  }

  componentDidMount() {
    this.props.setPlayerVisibility(false);
    this.getPaymentRequest();
  }

  getPaymentRequest = async () => {
    this.props.setLoader(true);
    try {
      var response = await requestWithBearer(
        "/user-service/api/user/payment/info",
        {
          method: "get",
        }
      );
      console.log(response.data);

      if (response.data) {
        var paypalId = response.data.paypalId;
        this.setState({ paypalId });
      }

      this.props.setLoader(false);
      return true;
    } catch (error) {
      console.log("Unable to get payment details");
      console.log(error);
      this.props.setLoader(false);
      return false;
    }
  };

  onInputChange = (event) => {
    if (event) event.preventDefault();
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  onFormSubmit = async (event, getUserProfile) => {
    if (event) event.preventDefault();

    if (!this.state.firstName) {
      alert("Invalid first name");
      return;
    }
    if (!this.state.firstName) {
      alert("Invalid last name");
      return;
    }
    if (!this.state.country) {
      alert("Invalid country");
      return;
    }
    if (!this.state.city) {
      alert("Invalid city");
      return;
    }
    if (!this.state.zipcode) {
      alert("Invalid zipcode");
      return;
    }
    if (!this.state.gender) {
      alert("Invalid gender");
      return;
    }

    this.props.setLoader(true);

    var userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      country: this.state.country,
      city: this.state.city,
      zipcode: this.state.zipcode,
      gender: this.state.gender,
    };

    console.log(userData);

    try {
      var userProfile = await requestWithBearer(
        "/user-service/api/user/profile",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: userData,
        }
      );
      console.log(userProfile);

      await getUserProfile();
    } catch (error) {
      console.log(error);
      alert("Unable to update profile");
    }

    this.props.setLoader(false);
  };

  onPaymentFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!this.state.paypalId) {
      alert("Invalid Paypal ID");
      return;
    }
    this.props.setLoader(true);

    var paymentData = {
      paypalId: this.state.paypalId,
    };

    console.log(paymentData);

    try {
      var userProfile = await requestWithBearer(
        "/user-service/api/user/payment/info",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: paymentData,
        }
      );
      console.log(userProfile);

      alert("Payment profile updated");
    } catch (error) {
      console.log(error);
      alert("Unable to update profile");
    }

    this.props.setLoader(false);
  };

  prefillUserData = (user) => {
    if (!user) return;

    var newState = this.state;
    if (newState.firstName === null && user.firstName != null)
      newState.firstName = user.firstName;
    if (newState.lastName === null && user.lastName != null)
      newState.lastName = user.lastName;
    if (newState.gender === null && user.gender != null)
      newState.gender = user.gender;
    if (newState.country === null && user.country != null)
      newState.country = user.country;
    if (newState.city === null && user.city != null) newState.city = user.city;
    if (newState.zipcode === null && user.zipcode != null)
      newState.zipcode = user.zipcode;

    this.state = newState;
  };

  onLoadImage = (event) => {
    if (!event.target.files[0])
      // cancel clicked
      return;

    if (event.target.files[0].size / 1024 > 1024 * 3) {
      alert("Image selected is larger than 3MB. Please select another image.");
      return;
    }

    if (
      event.target.files[0].type !== "image/jpeg" &&
      event.target.files[0].type !== "image/png"
    ) {
      alert("Only JPEG and PNG images are allowed");
      return;
    }

    var self = this;
    var file = event.target.files[0];
    var fileURL = URL.createObjectURL(file);
    var img = new Image();
    img.onload = function () {
      // var aspectRatio = this.width / this.height;
      // console.log("Aspect Ratio: " + aspectRatio);
      // if (aspectRatio < 0.99 || aspectRatio > 1.01) {
      //     swal("Image selected is not in 1:1 aspect ratio.");
      //     return;
      // }
      var newData = self.state.data;
      newData.fileUrl = fileURL;
      newData.file = file;
      self.setState({ data: newData, isModified: true });
      console.log(fileURL);
    };
    img.src = fileURL;
  };

  uploadImage = async () => {
    if (!this.state.isModified || !this.state.data.file) return;

    this.props.setLoader(true);

    try {
      var data = new FormData();
      data.append("image", this.state.data.file);

      var uploadResponse = await requestWithBearer(
        "/user-service/api/user/profile/image",
        {
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          body: data,
        }
      );
      console.log(uploadResponse);

      // await updateUserProfile();
    } catch (error) {
      console.log(error);
      alert("Unable to update profile image");
    }

    this.props.setLoader(false);
  };

  renderTnC = (getUserProfile) => {
    return (
      <>
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 10,
            paddingTop: "5rem",
            overflow: "auto",
          }}
        >
          <div
            className="w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="card" style={{ width: "60%" }}>
              <div className="card-header d-flex justify-content-between">
                <h6 className="card-title mb-0">Terms and Conditions</h6>
                <a
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ showTnC: false })}
                >
                  <i className="la la-times"></i>
                </a>
              </div>
              <div className="card-body">
                <form
                  //   onSubmit={(event) => {
                  //     event.preventDefault();
                  //     this.setState({ tncAccepted: true });
                  //   }}
                  onSubmit={(e) => {
                    this.onFormSubmit(e, getUserProfile);
                    this.setState({ showTnC: false });
                  }}
                >
                  <iframe
                    src={`/assets/pdf/MusicaFresh_Terms_and_Conditions.pdf?#scrollbar=1&toolbar=0&navpanes=0`}
                    style={{
                      height: "50vh",
                      width: "100%",
                      marginBottom: "1.5rem",
                    }}
                  />
                  <input
                    type="checkbox"
                    id="accept"
                    style={{
                      margin: "revert",
                      appearance: "revert",
                      WebkitAppearance: "revert",
                      MozAppearance: "revert",
                    }}
                    required
                  ></input>
                  <label htmlFor="accept">
                    {" "}
                    I accept Musicafresh Terms and Conditions
                  </label>
                  <br />
                  <div className="w-100 mt-2 text-center">
                    <button type="submit" className="btn btn-brand btn-air">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    let { user, getUserProfile } = this.context;

    // console.log(this.context);

    if (!user) return null;

    this.prefillUserData(user);

    return (
      <>
        <div className="banner bg-profile"></div>

        <div className="main-container" id="appRoute">
          <div className="row section">
            <div className="col-xl-10 mx-auto">
              <div className="row">
                <div className="col-xl-4 col-md-5">
                  <div className="card h-auto">
                    <div className="card-body text-center">
                      <div
                        className="avatar avatar-xl avatar-circle mx-auto mb-4 custom-file-input2"
                        style={{
                          backgroundImage: this.state.isModified
                            ? `url(${this.state.data.fileUrl})`
                            : user.imageUrl
                            ? `url(${user.imageUrl})`
                            : "url(../assets/images/users/thumb.jpg)",
                          backgroundSize: "cover",
                        }}
                      >
                        {/* <img src={user.imageUrl ? user.imageUrl : "../assets/images/users/thumb.jpg"} alt="user" /> */}
                        <input
                          type="file"
                          accept="image/jpeg,image/png"
                          name="imageURL"
                          onChange={this.onLoadImage}
                        />
                      </div>
                      <h6 className="mb-3">Hello {user.firstName}</h6>
                      <p className="mb-1">Preferred: 420x420(px)</p>
                      <p>Minimum: 128x128(px)</p>
                      <button
                        type="button"
                        className="btn btn-danger btn-air"
                        onClick={this.uploadImage}
                      >
                        Save Image
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-md-7">
                  <div className="card h-auto">
                    <div className="card-body">
                      <form
                        action="#"
                        className="row"
                        // onSubmit={(e) => this.onFormSubmit(e, getUserProfile)}
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (!user.lastName) this.setState({ showTnC: true });
                          else this.onFormSubmit(e, getUserProfile);
                        }}
                      >
                        <div className="col-xl-6 form-group">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="form-control"
                            value={this.state.firstName}
                            onChange={this.onInputChange}
                            required
                          />
                        </div>
                        <div className="col-xl-6 form-group">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="form-control"
                            value={this.state.lastName}
                            onChange={this.onInputChange}
                            required
                          />
                        </div>

                        <div className="col-xl-6 form-group">
                          <label htmlFor="gender" className="form-label">
                            Gender
                          </label>
                          <select
                            name="gender"
                            id="gender"
                            name="gender"
                            className="form-control"
                            value={this.state.gender}
                            onChange={this.onInputChange}
                            required
                          >
                            <option selected disabled>
                              -- Choose Gender --
                            </option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="OTHER">Other</option>
                          </select>
                        </div>
                        <div className="col-xl-6 form-group">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          {/* <select name="country" id="country" name="country" className="form-control" value={this.state.country} onChange={onInputChange}>
                                                <option value="India">India</option>
                                                <option value="India">Australia</option>
                                            </select> */}
                          <CountryDropdown
                            name="country"
                            id="country"
                            name="country"
                            className="form-control"
                            value={this.state.country}
                            onChange={(val) =>
                              this.setState({
                                country: val,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="col-xl-6 form-group">
                          <label htmlFor="city" className="form-label">
                            City
                          </label>
                          {/* <select name="city" id="city" name="city" className="form-control" value={this.state.city} onChange={onInputChange}>
                                                <option value="Pune">Pune</option>
                                                <option value="Pune">Delhi</option>
                                                <option value="Pune">Mumbai</option>
                                            </select> */}
                          <RegionDropdown
                            name="city"
                            id="city"
                            name="city"
                            className="form-control"
                            country={this.state.country}
                            value={this.state.city}
                            onChange={(val) =>
                              this.setState({
                                city: val,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="col-xl-6 form-group">
                          <label htmlFor="zipcode" className="form-label">
                            Zipcode
                          </label>
                          <input
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            className="form-control"
                            value={this.state.zipcode}
                            onChange={this.onInputChange}
                            required
                          />
                        </div>
                        {/*<div className="col-xl-6 form-group">*/}
                        {/*    <label htmlFor="loc" className="form-label">Location</label>*/}
                        {/*    <input type="text" id="loc" className="form-control" value="USA"/>*/}
                        {/*</div>*/}
                        {/*<div className="col-12 form-group">*/}
                        {/*    <label htmlFor="about" className="form-label">About</label>*/}
                        {/*    <textarea name="about" id="about" cols="30" rows="5"*/}
                        {/*              className="form-control"></textarea>*/}
                        {/*</div>*/}
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-air"
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-md-7 ml-auto">
                  <div className="card h-auto">
                    <div className="card-body">
                      <form
                        action="#"
                        className="row"
                        onSubmit={this.onPaymentFormSubmit}
                      >
                        <div className="col-xl-12 form-group">
                          <h5>Payment Details</h5>
                        </div>
                        {/* <div className="col-xl-6 form-group">
                                                    <label
                                                        htmlFor="title"
                                                        className="form-label"
                                                    >
                                                        Display Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="title"
                                                        name="title"
                                                        className="form-control"
                                                        value={this.state.title}
                                                        onChange={
                                                            this.onInputChange
                                                        }
                                                        required
                                                    />
                                                </div> */}
                        <div className="col-xl-6 form-group">
                          <label htmlFor="paypalId" className="form-label">
                            Paypal ID
                          </label>
                          <input
                            type="text"
                            id="paypalId"
                            name="paypalId"
                            className="form-control"
                            value={this.state.paypalId}
                            onChange={this.onInputChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-air"
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showTnC && this.renderTnC(getUserProfile)}
      </>
    );
  }
}

Profile.contextType = UserContext;
