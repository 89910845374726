import React from "react";
import { UserContext } from "../../auth/providers";
import { signOut } from "../../firebase/Firebase";
import { Link, NavLink } from "react-router-dom";
import { setSidebarOpen } from "../../redux/actions/actions";
import { connect } from "react-redux";

class Sidebar extends React.Component {
  render() {
    let user = this.context.user;

    return (
      <aside id="sidebar" className="sidebar-primary">
        <div className="sidebar-header d-flex align-items-center">
          <a href="index.html" className="brand">
            <img
              src="/assets/images/logos/logo.png"
              alt="listen-app"
              style={{ height: "2.55rem" }}
            />
          </a>

          <button
            type="button"
            className="btn btn-dark px-3 py-2 ml-auto"
            id="hideSidebar"
            onClick={() => this.props.setSidebarOpen(false)}
          >
            <i className="ion-ios-arrow-back font-lg"></i>
          </button>

          {/* <button
            type="button"
            className="btn toggle-menu"
            id="toggleSidebar"
            onClick={() => console.log(this.props.favorites)}
            // onClick={() => this.props.setSidebarOpen(!this.props.sidebarOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button> */}
        </div>

        <nav className="navbar">
          <ul className="navbar-nav ps" data-scrollable="true">
            <li className="nav-item nav-header">Menu</li>

            <li className="nav-item">
              <NavLink to="/home" className="nav-link" activeClassName="active">
                <i className="la la-home"></i> <span> Home</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/songs"
                className="nav-link"
                activeClassName="active"
              >
                <i className="la la-music"></i> <span> Songs</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/genres"
                className="nav-link"
                activeClassName="active"
              >
                <i className="la la-diamond"></i> <span> Genres</span>
              </NavLink>
            </li>
            {user && (
              <NavLink
                to="/favorites"
                className="nav-link"
                activeClassName="active"
              >
                <i className="ion-ios-heart"></i>{" "}
                <span>
                  {" "}
                  My Favorites{" "}
                  {this.props.favorites.length > 0
                    ? `(${this.props.favorites.length})`
                    : ""}
                </span>
              </NavLink>
            )}
            {user && (
              <NavLink to="/cart" className="nav-link" activeClassName="active">
                <i className="ion-ios-cart"></i>{" "}
                <span>
                  {" "}
                  My Cart{" "}
                  {this.props.cart.length > 0
                    ? `(${this.props.cart.length})`
                    : ""}
                </span>
              </NavLink>
            )}
            {user && (
              <NavLink
                to="/purchases"
                className="nav-link"
                activeClassName="active"
              >
                <i className="ion-ios-wallet"></i> <span> My Purchases</span>
              </NavLink>
            )}
            {user &&
              (user && user.role && user.role.includes("PRODUCER") ? (
                <NavLink
                  to="/myTracks"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="ion-ios-musical-note"></i>{" "}
                  <span>My Tracks</span>
                </NavLink>
              ) : (
                <NavLink
                  to="/producer"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="ion-ios-musical-note"></i>{" "}
                  <span>Be a Producer</span>
                </NavLink>
              ))}
            {user && (
              <NavLink
                to="/profile"
                className="nav-link"
                activeClassName="active"
              >
                <i className="ion-ios-contact"></i> <span> Profile</span>
              </NavLink>
            )}

            {
              <NavLink
                to="/contact"
                className="nav-link"
                activeClassName="active"
              >
                <i className="ion-ios-mail"></i> <span> Contact Us</span>
              </NavLink>
            }

            {/*{*/}
            {/*    user &&*/}
            {/*    <li className="nav-item"><a href="settings.html" className="nav-link"><i*/}
            {/*        className="ion-ios-settings"></i> <span>Settings</span></a></li>*/}
            {/*}*/}
            {/*{*/}
            {/*    user &&*/}
            {/*    <li className="nav-item"><a href="history.html" className="nav-link">*/}
            {/*        <i className="ion-ios-timer"/> <span>History</span></a></li>*/}
            {/*}*/}
          </ul>
        </nav>

        {user && (
          <div className="sidebar-footer">
            <a
              href="#"
              className="btn btn-block btn-danger btn-air btn-bold"
              onClick={signOut}
            >
              <i className="la la-sign-out"></i>
              <span>Signout</span>
            </a>
          </div>
        )}
      </aside>
    );
  }
}

Sidebar.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    sidebarOpen: state.sidebarOpen,
    cart: state.cart,
    favorites: state.favorites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarOpen: (sidebarOpen) => dispatch(setSidebarOpen(sidebarOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
