export const setSubState = (subState, keyVal) => {
    this.setState(prevState => {
        if (prevState[subState] != null)
            return ({
                [subState]: {
                    ...prevState[subState],
                    ...keyVal,
                }
            });
        else
            return ({
                [subState]: {
                    ...keyVal,
                }
            });
    });
}

export const retryCall = async (funcToCall, maxTries, delay) => {
    var success = await funcToCall();
    if (!success && maxTries > 0) {
        console.log(maxTries);
        setTimeout(() => retryCall(funcToCall, maxTries - 1, delay), delay);
    }
}