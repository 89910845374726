import React from "react";
import Application from "./components/Application/Application";
import { UserProvider } from "./auth/providers";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./common/ScollToTop";

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={store}>
        <UserProvider>
          <Router>
            {/* <ScrollToTop> */}
              <Application />
            {/* </ScrollToTop> */}
          </Router>
        </UserProvider>
      </Provider>
    );
  }
}
