import React, { Component } from "react";
import WaveSurfer from "wavesurfer.js";

// import { WaveformContianer, Wave, PlayButton } from './Waveform.styled';

export default class Waveform extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      volume: 100,
      song: {},
      visible: false,
    };

    this.track = React.createRef();
    // this.waveform = React.createRef();

    console.log("WAVEFORM CONSTRUCTOR");
  }

  componentDidMount() {
    console.log("WAVEFORM MOUNTED");
    // const track = document.querySelector('#track');

    this.waveform = WaveSurfer.create({
      barWidth: 3,
      cursorWidth: 1,
      container: "#waveform",
      // backend: 'WebAudio',
      height: 80,
      progressColor: "#753fdc",
      responsive: true,
      waveColor: "#EFEFEF",
      cursorColor: "transparent",
      xhr: {
        cache: "default",
        mode: "cors",
        method: "GET",
        // credentials: 'same-origin',
        // redirect: 'follow',
        // referrer: 'client',
        // credentials: "include",
        credentials: false,
        // withCredentials: "false",
        headers: [
          { key: "cache-control", value: "no-cache" },
          { key: "pragma", value: "no-cache" },
        ],
      },
    });

    this.waveform.on("ready", () => {
      this.handlePlay();
    });

    // var xhttp = new XMLHttpRequest();
    // xhttp.onreadystatechange = function (xhr, status) {
    //     if (this.readyState == 4) {
    //         if (this.status == 200) {// Typical action to be performed when the document is ready:
    //             // document.getElementById("demo").innerHTML = xhttp.responseText;
    //             console.log("Song downloaded!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //         }
    //         else {
    //             console.log("Error: " + xhttp.statusText);
    //         }
    //     }
    // };
    // xhttp.open("GET", "https://storage.googleapis.com/musicafresh-staging-user/user/jDSpDLu875fmAyJS3up119QyUD93/private/track/5/audio/023%20-%20The%20Verve%20-%20Bittersweet%20Symphony%20(1997).mp3", true);
    // xhttp.send();
  }

  componentWillReceiveProps(newProps) {
    // recreateWaveform = (e) => {
    if (
      newProps.song &&
      newProps.song.musicSrc &&
      newProps.song.musicSrc !== this.state.song.musicSrc
    ) {
      console.log("Selected track:");
      console.log(newProps.song);

      if (this.props.song) {
        this.handleStop();
        this.waveform.empty();
        // this.waveform.destroy();
      }

      this.setState({ song: newProps.song });

      // this.waveform.load(this.track.current);
      this.waveform.load(newProps.song.musicSrc);
      // this.waveform.load('https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3');

      // if (!this.state.playing)
      // this.waveform.play();

      // this.handlePlay();
    }

    if (newProps.visible != null && newProps.visibile !== this.state.visible) {
      this.setState({ visible: newProps.visible });
      if (!newProps.visible) this.handlePause();
    }
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
    this.waveform.playPause();
  };

  handlePlay = () => {
    this.setState({ playing: true });
    this.waveform.play();
  };

  handlePause = () => {
    this.setState({ playing: false });
    this.waveform.pause();
  };

  handleStop = () => {
    this.setState({ playing: false });
    this.waveform.stop();
  };

  handleVolume = (e) => {
    var volume = e.target.value;
    if (volume > 100) volume = 100;
    if (volume < 0) volume = 0;

    this.setState({ volume });
    this.waveform.setVolume(volume / 100.0);
  };

  skipForward = () => {
    this.waveform.skipForward();
  };

  skipBackward = () => {
    this.waveform.skipBackward();
  };

  render() {
    const url = "https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3";

    return (
      <>
        {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                        background: "transparent",
                    }}
                >
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "60px",
                            height: "60px",
                            background: "#EFEFEF",
                            borderRadius: "50%",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            paddingBottom: "3px",
                        }}
                        onClick={this.handlePlay}
                    >
                        {!this.state.playing ? 'Play' : 'Pause'}
                    </button>
                    <div
                        id="waveform"
                        // ref={this.waveform}
                        style={{
                            width: "100%",
                            height: "90px",
                        }}
                    />
                    <audio
                        ref={this.track}
                        src={this.props.song.musicSrc}
                    />
                </div> */}

        <div
          id="audioPlayer"
          className="player-primary"
          style={{
            visibility: this.props.visible ? "visible" : "hidden",
            overflow: "hidden",
          }}
        >
          {/* <div id="progress-container">
                        <input type="range" className="amplitude-song-slider" />
                        <progress className="audio-progress audio-progress--played amplitude-song-played-progress"></progress>
                        <progress className="audio-progress audio-progress--buffered amplitude-buffered-progress" value="0"></progress>
                    </div> */}

          <div className="audio h-100">
            <div className="song-image h-100">
              {/* <img
                                src={
                                    this.props.song && this.props.song.cover
                                        ? this.props.song.cover
                                        : "../assets/images/cover/small/1.jpg"
                                }
                                alt=""
                            /> */}
              <div
                style={{
                  backgroundImage:
                    'url("' +
                    (this.props.song && this.props.song.cover
                      ? this.props.song.cover
                      : "../assets/images/cover/small/1.jpg") +
                    '")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: "100%",
                }}
                alt=""
              />
            </div>
            <div className="song-info pl-3">
              <span className="song-name d-inline-block text-truncate">
                {this.props.song.name}
              </span>
              <span className="song-artists d-block text-muted">
                {this.props.song.description}
              </span>
            </div>
          </div>

          <div style={{ height: "100%", flexGrow: "1" }}>
            <div
              id="waveform"
              // ref={this.waveform}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>

          <div className="audio-controls">
            {/* <div className="audio-controls--left d-flex mr-auto">
                            <button className="btn btn-icon-only amplitude-repeat"><i className="ion-md-sync"></i></button>
                        </div> */}
            <div className="audio-controls--main d-flex">
              <button className="btn btn-icon-only" onClick={this.skipBackward}>
                <i className="ion-md-skip-backward"></i>
              </button>
              <button
                className="btn btn-air btn-pill btn-default btn-icon-only"
                onClick={this.handlePlayPause}
              >
                {!this.state.playing ? (
                  <i className="ion-md-play"></i>
                ) : (
                  <i className="ion-md-pause"></i>
                )}
              </button>
              <button className="btn btn-icon-only" onClick={this.skipForward}>
                <i className="ion-md-skip-forward"></i>
              </button>
            </div>
            {/* <div className="audio-controls--right d-flex ml-auto">
                            <button className="btn btn-icon-only amplitude-shuffle amplitude-shuffle-off"><i className="ion-md-shuffle"></i></button>
                        </div> */}
          </div>

          <div className="d-flex align-items-end pr-4">
            {/* <span className="mr-4">
                            <span className="amplitude-current-minutes" ></span>:<span className="amplitude-current-seconds"></span> /
                        <span className="amplitude-duration-minutes"></span>:<span className="amplitude-duration-seconds"></span>
                        </span> */}
            <div className="audio-volume dropdown">
              <button
                className="btn btn-icon-only"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ion-md-volume-low"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right volume-dropdown-menu">
                <input
                  type="range"
                  min={0}
                  max={100}
                  value={this.state.volume}
                  onChange={this.handleVolume}
                />
              </div>
            </div>

            {/* <div className="dropleft">
                            <button className="btn btn-icon-only" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="la la-ellipsis-v"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                    <a href="#" className="dropdown-link">
                                        <i className="la la-heart-o"></i> <span>Favorite</span>
                                    </a>
                                </li>
                                <li className="dropdown-item">
                                    <a href="#" className="dropdown-link">
                                        <i className="la la-plus"></i> <span>Add to Playlist</span>
                                    </a>
                                </li>
                                <li className="dropdown-item">
                                    <a href="#" className="dropdown-link">
                                        <i className="la la-download"></i> <span>Download</span>
                                    </a>
                                </li>
                                <li className="dropdown-item">
                                    <a href="#" className="dropdown-link">
                                        <i className="la la-share-alt"></i> <span>Share</span>
                                    </a>
                                </li>
                                <li className="dropdown-item">
                                    <a href="song-details.html" className="dropdown-link">
                                        <i className="la la-info-circle"></i>
                                        <span>Song Info</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button className="btn btn-icon-only" id="playList"><i className="ion-md-musical-note"></i></button> */}
          </div>

          {this.props.song && this.props.song.musicSrc && (
            <audio>
              <source
                ref={this.track}
                // src={this.props.song.musicSrc}
                // src="https://storage.googleapis.com/musicafresh-staging-user/user/jDSpDLu875fmAyJS3up119QyUD93/private/track/5/audio/023%20-%20The%20Verve%20-%20Bittersweet%20Symphony%20(1997).mp3"
                onLoad={() => console.log("Loaded song!")}
              />
            </audio>
          )}
        </div>
      </>
    );
  }
}
