export const DOWNLOAD_TYPES = {
  masteredMp3: {
    url: "AUDIO1",
    obj: "audio1",
    title: "Mastered MP3",
    accept: ".mp3",
  },
  masteredWav: {
    url: "AUDIO2",
    obj: "audio2",
    title: "Mastered WAV",
    accept: ".wav",
  },
  unmasteredMp3: {
    url: "AUDIO3",
    obj: "audio3",
    title: "Unmastered MP3",
    accept: ".mp3",
  },
  unmasteredWav: {
    url: "AUDIO4",
    obj: "audio4",
    title: "Unmastered WAV",
    accept: ".wav",
  },
  stem: { url: "STEM1", obj: "steam1", title: "Stem", accept: ".txt" },
  midi1: { url: "MIDI1", obj: "midi1", title: "MIDI 1", accept: ".midi,.mid" },
  midi2: { url: "MIDI2", obj: "midi2", title: "MIDI 2", accept: ".midi,.mid" },
  midi3: { url: "MIDI3", obj: "midi3", title: "MIDI 3", accept: ".midi,.mid" },
  midi4: { url: "MIDI4", obj: "midi4", title: "MIDI 4", accept: ".midi,.mid" },
  midi5: { url: "MIDI5", obj: "midi5", title: "MIDI 5", accept: ".midi,.mid" },
};
