import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import CheckoutForm from "./CheckoutForm";
import { setSubState, retryCall } from "../../common/common";

import { ElementsConsumer } from "@stripe/react-stripe-js";
import {
  addToCart,
  fetchCart,
  fetchFavorites,
  removeFromCart,
} from "../../api/TracksApiController";
import { connect } from "react-redux";

class MyCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //   cart: [],
      //   cartInfo: {},
      cartTotal: 0,
      showCheckout: false,
    };
  }

  componentDidMount() {
    this.props.setPlayerVisibility(false);
    // retryCall(this.getCartItems, 3, 2000);
    if (this.context.user) {
      fetchCart(true);
    }
    this.getTotal(this.props);
  }

  componentWillReceiveProps = (newProps) => {
    this.getTotal(newProps);
  };

  //   componentWillReceiveProps = (newProps) => {
  //     if (this.context.user) {
  //       fetchCart();
  //       fetchFavorites();
  //     }
  //   };

  // onInputChange = event => {
  //     // if (event)
  //     //     event.preventDefault();
  //     let value = event.target.value
  //     // this.setState({ [event.target.name]: value });
  //     this.setSubState('addTrack', { [event.target.name]: value });
  // };

  //   getCartItems = async () => {
  //     try {
  //       var response = await requestWithBearer(`/tracks-service/api/cart`, {
  //         method: "get",
  //         headers: { "Content-Type": "application/json" },
  //         // body: newTrackData,
  //       });

  //       var requestData = response.data;
  //       console.log(requestData);

  //       var data = {};
  //       var total = 0;
  //       if (requestData) {
  //         data.cartInfo = requestData;
  //         if (requestData.items) {
  //           data.cart = [...requestData.items];

  //           for (var i = 0; i < requestData.items.length; i++) {
  //             if (requestData.items[i]?.price?.value) {
  //               total += requestData.items[i].price.value;
  //             }
  //           }
  //           data.cartTotal = total;

  //           this.props.setCartCount(requestData.items.length);
  //         }
  //       }

  //       this.setState(data);

  //       return true;
  //     } catch (error) {
  //       console.log("Unable to get cart items");
  //       console.log(error);
  //       return false;
  //     }
  //   };

  addToCartSong = async (track) => {
    // this.props.setLoader(true);

    if (this.isInCart(track)) {
      await removeFromCart(track);
    }

    // await this.getCartItems();
    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isInCart = (song) => {
    if (!this.props.cart) return false;

    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].id === song.id) return true;
    }

    return false;
  };

  checkout = async () => {
    this.props.setLoader(true);

    try {
      var response = await requestWithBearer(
        `/tracks-service/api/order/checkout`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          // body: userData,
        }
      );

      var requestData = response.data;
      console.log(requestData);

      // alert("Song added to cart!");
      this.setState({
        order: requestData,
        showCheckout: true,
      });
    } catch (error) {
      console.log(error);
      alert("Unable to create order");
    }

    this.props.setLoader(false);
  };

  getTotal = (porpsObj) => {
    var total = 0;
    for (var i = 0; i < porpsObj.cart.length; i++) {
      if (porpsObj.cart[i]?.price?.value) {
        total += porpsObj.cart[i].price.value;
      }
    }

    // return total;
    this.setState({ cartTotal: total });
  };

  render() {
    let user = this.context.user;

    if (!user) return null;

    return (
      <>
        {/* <div className="row align-items-end">
                    <span className="col-6 font-weight-bold">{this.state.cartInfo.totalElements} Results</span>
                    <div className="col-6 ml-auto">
                        <form action="#" className="form-inline justify-content-end">
                            <label className="mr-2" htmlFor="filter2">Filter By:</label>
                            <select className="custom-select mr-sm-2" id="filter2">
                                <option selected>Popular</option>
                                <option value="1">A-Z</option>
                                <option value="2">Z-A</option>
                            </select>
                        </form>
                    </div>
                    <div className="col-12">
                        <hr />
                    </div>
                </div> */}
        <div className="banner bg-cart"></div>

        <div className="main-container" id="appRoute">
          <div className="row align-items-end">
            <span className="col-6 font-weight-bold">
              Items in cart: {this.props.cart.length}
            </span>
            <div className="col-6 ml-auto">
              <form
                action="#"
                className="form-inline justify-content-end"
                style={{ visibility: "hidden" }}
              >
                <label className="mr-2" htmlFor="filter2">
                  Filter By:
                </label>
                <select className="custom-select mr-sm-2" id="filter2">
                  <option selected>Popular</option>
                  <option value="1">A-Z</option>
                  <option value="2">Z-A</option>
                </select>
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="section custom-list">
            {this.props.cart.map((song) => (
              <div className="custom-list--item" key={song.id}>
                <div className="text-dark custom-card--inline">
                  <div
                    className="custom-card--inline-img"
                    style={{ flex: "0 0 10rem", maxWidth: "10rem" }}
                  >
                    <img
                      src={song.imageUrl}
                      alt=""
                      className="card-img--radius-sm"
                    />
                  </div>
                  <div
                    className="custom-card--inline-desc"
                    style={{
                      flex: "0 0 calc(100% - 10rem)",
                      maxWidth: "calc(100% - 10rem)",
                    }}
                  >
                    <p className="text-truncate mb-0">{song.title}</p>
                    <p className="text-truncate text-muted font-sm mb-0">
                      {song.description}
                    </p>
                  </div>
                </div>
                <ul className="custom-card--labels d-flex ml-auto">
                  <li>
                    <b>${song.price?.value}</b>
                  </li>
                  <li>
                    <a
                      className="badge badge-pill badge-danger"
                      onClick={() => this.addToCartSong(song)}
                      style={{ color: this.isInCart(song) ? "white" : "grey" }}
                    >
                      <i className="la la-trash"></i>
                    </a>
                  </li>
                  {/* <li className="dropleft">
                                            <a href="#" className="btn btn-icon-only p-0 w-auto h-auto"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="la la-ellipsis-h"></i>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link favorite">
                                                        <i className="la la-heart-o"></i>
                                                        <span>Favorite</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-plus"></i>
                                                        <span>Add to Playlist</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-download"></i>
                                                        <span>Download</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-share-alt"></i>
                                                        <span>Share</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="song-details.html" className="dropdown-link">
                                                        <i className="la la-info-circle"></i>
                                                        <span>Song Info</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li> */}
                </ul>
              </div>
            ))}
            <hr />
            <div className="custom-list--item d-flex justify-content-end">
              <b>Total: ${this.state.cartTotal}</b>
            </div>
            <div className="custom-list--item d-flex justify-content-end">
              <button
                className="btn btn-primary"
                disabled={this.props.cart.length <= 0}
                onClick={this.checkout}
              >
                Checkout
              </button>
            </div>

            {this.state.showCheckout && this.state.order != null && (
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <CheckoutForm
                    stripe={stripe}
                    elements={elements}
                    cartTotal={this.state.cartTotal}
                    order={this.state.order}
                    close={() => this.setState({ showCheckout: false })}
                    setLoader={this.props.setLoader}
                  />
                )}
              </ElementsConsumer>
            )}
          </div>
        </div>
      </>
    );
  }
}

MyCart.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    favorites: state.favorites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setCart: (cart) => dispatch(setCart(cart)),
    // setFavorites: (favorites) => dispatch(setFavorites(favorites)),
  };
};

export default connect(mapStateToProps, null)(MyCart);
