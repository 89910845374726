import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { uiConfig, auth } from '../../firebase/Firebase';
import { UserContext } from "../../auth/providers";

export default class Login extends React.Component {
    state = {
    }

    render() {
        return (
            <div className="modal fade" id="signIn" tabIndex="-1" role="dialog" aria-labelledby="loginTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 pb-0">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <h6 className="modal-title text-center mb-3" id="loginTitle">Login For Awesome Listing Experience</h6>
                        <div className="modal-body">
                            <form action="#" className="mx-4 pb-5">
                                <div className="social-login">
                                    {/* <a href="#" className="w-100 btn btn-social btn-facebook btn-default-air">
                                        <span className="btn-content">
                                            <i className="ion-logo-facebook"></i>
                                            <span className="pl-2">Login With Facebook</span>
                                        </span>
                                    </a>
                                    <a href="#" className="w-100 mt-3 btn btn-social btn-google btn-default-air">
                                        <span className="btn-content">
                                            <i className="ion-logo-google"></i>
                                            <span className="pl-2">Login With Google</span>
                                        </span>
                                    </a> */}
                                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                                </div>
                                {/* <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email or mobile number" value="listen_app@kri8thm.com" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Password" value="123456789" />
                                </div>
                                <a href="index.html" className="btn btn-block btn-bold btn-air btn-info load-page">Login</a> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}