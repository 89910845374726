import store from "../redux/store/store";

import {
  deleteCart,
  deleteFavorite,
  getCart,
  getFavorites,
  getGenres,
  getLatestSongs,
  getPopularSongs,
  getTracks,
  postCart,
  postFavorite,
} from "./TracksApiModel";
import Swal from "sweetalert2";
import {
  addLoadingCart,
  addLoadingFavorites,
  removeLoadingCart,
  removeLoadingFavorites,
  setCart,
  setFavorites,
  setGenres,
  setLatestSongs,
  setPopularSongs,
  setSongs,
  setSongsPage,
  setTotalSongsPages,
} from "../redux/actions/actions";

// params: { status, genreId, sortBy, searchTerm, searchType, pageNumber, pageSize, paged }
export const fetchSongs = async (
  params,
  forceFetch = false,
  showError = false
) => {
  let state = store.getState();
  if (!forceFetch && state.songs && state.songs.length > 0) {
    return;
  }

  try {
    // if (state.songsPage === null) store.dispatch(setSongsPage(0));

    let fetchedData = await getTracks(params);
    console.log(fetchedData);
    if (fetchedData.content) {
      store.dispatch(setSongs(fetchedData.content));
    }
    if (fetchedData.totalPages) {
      store.dispatch(setTotalSongsPages(fetchedData.totalPages));
    }
  } catch (err) {
    console.log(err);
    if (showError && err.message) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }
};

export const fetchGenres = async (forceFetch = false, showError = false) => {
  let state = store.getState();
  if (!forceFetch && state.genres && state.genres.length > 0) {
    return;
  }

  try {
    let fetchedData = await getGenres();
    console.log(fetchedData);
    store.dispatch(setGenres(fetchedData));
  } catch (err) {
    console.log(err);
    if (showError && err.message) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }
};

export const fetchFavorites = async (forceFetch = false, showError = false) => {
  let state = store.getState();
  if (!forceFetch && state.favorites && state.favorites.length > 0) {
    return;
  }

  try {
    let fetchedData = await getFavorites();
    if (fetchedData.items) {
      console.log(fetchedData.items);
      store.dispatch(setFavorites(fetchedData.items));
    }
  } catch (err) {
    console.log(err);
    if (showError && err.message) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }
};

export const addToFavorite = async (song, showError = false) => {
  let state = store.getState();
  console.log("Adding " + song.id);
  if (!song || !song.id || state.loadingFavorites.includes(song.id)) {
    console.log("Adding " + song.id + " - FAILED!");
    return;
  }

  let favoritesOriginal = [...state.favorites];
  try {
    let tempFavorites = [...favoritesOriginal, song];
    store.dispatch(setFavorites(tempFavorites));
    store.dispatch(addLoadingFavorites(song.id));

    let fetchedData = await postFavorite(song.id);
    if (fetchedData.items) {
      store.dispatch(setFavorites(fetchedData.items));
      store.dispatch(removeLoadingFavorites(song.id));
      console.log("Adding " + song.id + " - SUCCEEDED!");
    }
  } catch (err) {
    // fetchFavorites(true);
    store.dispatch(setFavorites(favoritesOriginal));
    store.dispatch(removeLoadingFavorites(song.id));

    console.log(err);
    if (showError && err.message) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }
};

export const removeFromFavorite = async (song, showError = false) => {
  let state = store.getState();
  console.log("Removing " + song.id);
  if (!song || !song.id || state.loadingFavorites.includes(song.id)) {
    console.log("Removing " + song.id + " - FAILED!");
    return;
  }

  let favoritesOriginal = [...state.favorites];
  try {
    let tempFavorites = favoritesOriginal.filter((item) => item.id !== song.id);
    store.dispatch(setFavorites(tempFavorites));
    store.dispatch(addLoadingFavorites(song.id));

    let fetchedData = await deleteFavorite(song.id);
    if (fetchedData.items) {
      store.dispatch(setFavorites(fetchedData.items));
      store.dispatch(removeLoadingFavorites(song.id));
      console.log("Removing " + song.id + " - SUCCEEDED!");
    }
  } catch (err) {
    // fetchFavorites(true);
    store.dispatch(setFavorites(favoritesOriginal));
    store.dispatch(removeLoadingFavorites(song.id));

    console.log(err);
    if (showError && err.message) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
    }
  }
};

export const fetchCart = async (forceFetch = false, showError = false) => {
  let state = store.getState();
  if (forceFetch || !state.cart || state.cart.length === 0) {
    try {
      let fetchedData = await getCart();
      if (fetchedData.items) {
        store.dispatch(setCart(fetchedData.items));
      }
    } catch (err) {
      console.log(err);
      if (showError && err.message) {
        Swal.fire({
          icon: "error",
          text: err.message,
        });
      }
    }
  }
};

export const addToCart = async (song, showError = false) => {
  let state = store.getState();
  if (song && song.id && !state.loadingCart.includes(song.id)) {
    let cartOriginal = [...state.cart];
    try {
      let tempCart = [...cartOriginal, song];
      store.dispatch(setCart(tempCart));
      store.dispatch(addLoadingCart(song.id));

      let fetchedData = await postCart(song.id);
      if (fetchedData.items) {
        store.dispatch(setCart(fetchedData.items));
        store.dispatch(removeLoadingCart(song.id));
      }
    } catch (err) {
      store.dispatch(setCart(cartOriginal));
      store.dispatch(removeLoadingCart(song.id));

      console.log(err);
      if (showError && err.message) {
        Swal.fire({
          icon: "error",
          text: err.message,
        });
      }
    }
  }
};

export const removeFromCart = async (song, showError = false) => {
  let state = store.getState();
  if (song && song.id && !state.loadingCart.includes(song.id)) {
    let cartOriginal = [...state.cart];
    try {
      let tempCart = cartOriginal.filter((item) => item.id !== song.id);
      store.dispatch(setCart(tempCart));
      store.dispatch(addLoadingCart(song.id));

      let fetchedData = await deleteCart(song.id);
      if (fetchedData.items) {
        store.dispatch(setCart(fetchedData.items));
        store.dispatch(removeLoadingCart(song.id));
      }
    } catch (err) {
      store.dispatch(setCart(cartOriginal));
      store.dispatch(removeLoadingCart(song.id));

      console.log(err);
      if (showError && err.message) {
        Swal.fire({
          icon: "error",
          text: err.message,
        });
      }
    }
  }
};

export const fetchPopularSongs = async (
  forceFetch = false,
  showError = false
) => {
  let state = store.getState();
  if (forceFetch || !state.popular || state.popular.length === 0) {
    try {
      let fetchedData = await getPopularSongs();
      if (fetchedData.content) {
        store.dispatch(setPopularSongs(fetchedData.content));
      }
    } catch (err) {
      console.log(err);
      if (showError && err.message) {
        Swal.fire({
          icon: "error",
          text: err.message,
        });
      }
    }
  }
};

export const fetchLatestSongs = async (
  forceFetch = false,
  showError = false
) => {
  let state = store.getState();
  if (forceFetch || !state.popular || state.popular.length === 0) {
    try {
      let fetchedData = await getLatestSongs();
      if (fetchedData.content) {
        store.dispatch(setLatestSongs(fetchedData.content));
      }
    } catch (err) {
      console.log(err);
      if (showError && err.message) {
        Swal.fire({
          icon: "error",
          text: err.message,
        });
      }
    }
  }
};
