import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div
        id="loading"
        style={this.props.show ? { display: "block" } : { display: "none" }}
      >
        <div className="loader">
          <div className="eq">
            <span className="eq-bar eq-bar--1"></span>
            <span className="eq-bar eq-bar--2"></span>
            <span className="eq-bar eq-bar--3"></span>
            <span className="eq-bar eq-bar--4"></span>
            <span className="eq-bar eq-bar--5"></span>
            <span className="eq-bar eq-bar--6"></span>
          </div>
          <span className="text">{this.props.loaderText}</span>
        </div>
      </div>
    );
  }
}

export default Loader;
