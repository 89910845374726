import React from "react";
import Swal from "sweetalert2";

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    // Swal.fire({
    //   imageUrl: "/assets/pdf/MusicaFresh_Terms_and_Conditions.pdf",
    //   imageHeight: 500,
    //   imageAlt: "A tall image",
    // });
  }

  render() {
    return (
      <>
        <div className="banner bg-contact"></div>

        <div className="main-container" id="appRoute">
          <div className="section">
            <div className="card h-auto">
              <div className="card-body text-center">
                <div className="row" style={{ minHeight: "20rem" }}>
                  <div className="col-xl-4 col-md-5 d-flex align-items-center justify-content-center">
                    <img
                      src="/assets/images/misc/email.png"
                      style={{ maxHeight: "12rem" }}
                    />
                  </div>
                  <div className="col-xl-8 col-md-7 d-flex align-items-center justify-content-center flex-column">
                    <div>
                      For enquiries and support, reach us at&nbsp;
                      <a
                        href="mailto:contact@musicafresh.com"
                        style={{ fontWeight: "bold" }}
                      >
                        contact@musicafresh.com
                      </a>
                    </div>
                    <br />
                    <div>
                      Musicafresh{" "}
                      <a
                        href="/assets/pdf/MusicaFresh_Terms_and_Conditions.pdf"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
