import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import { setSubState, retryCall } from "../../common/common";
// import ReactJkMusicPlayer from 'react-jinke-music-player';
// import 'react-jinke-music-player/assets/index.css';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Waveform from "../Waveform/Waveform";
import {
  addToCart,
  addToFavorite,
  fetchCart,
  fetchFavorites,
  removeFromCart,
  removeFromFavorite,
} from "../../api/TracksApiController";
import { connect } from "react-redux";

class MyFavorites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // favorites: [],
      // favoritesInfo: {},
      // cart: [],
      // playingSong: {},
    };

    // this.audioInstance = null
  }

  componentDidMount() {
    this.props.setPlayerVisibility(true);

    // Only fetch once when website is openend instead of everytime genre is loaded
    // retryCall(this.getFavorites, 3, 2000);
    // retryCall(this.getCartItems, 3, 2000);
    // if (this.context.user) {
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

  componentWillUnmount() {
    // this.setState({ playingSong: {} });
  }

  // componentWillReceiveProps = (newProps) => {
  //   if (this.context.user) {
  //     fetchFavorites();
  //     fetchCart();
  //   }
  // };

  // onInputChange = event => {
  //     // if (event)
  //     //     event.preventDefault();
  //     let value = event.target.value
  //     // this.setState({ [event.target.name]: value });
  //     this.setSubState('addTrack', { [event.target.name]: value });
  // };

  getFavorites = async () => {
    // try {
    //   var response = await requestWithBearer(`/tracks-service/api/favourties`, {
    //     method: "get",
    //     headers: { "Content-Type": "application/json" },
    //     // body: newTrackData,
    //   });
    //   var requestData = response.data;
    //   console.log(requestData);
    //   var data = {};
    //   if (requestData) {
    //     data.favoritesInfo = requestData;
    //     if (requestData.items) data.favorites = [...requestData.items];
    //   }
    //   this.setState(data);
    //   return true;
    // } catch (error) {
    //   console.log("Unable to get favorites");
    //   console.log(error);
    //   return false;
    // }
  };

  // getCartItems = async () => {
  //   try {
  //     var response = await requestWithBearer(`/tracks-service/api/cart`, {
  //       method: "get",
  //       headers: { "Content-Type": "application/json" },
  //       // body: newTrackData,
  //     });

  //     var requestData = response.data;
  //     console.log(requestData);

  //     if (requestData.items) {
  //       this.setState({ cart: requestData.items });
  //       this.props.setCartCount(requestData.items.length);
  //     }
  //     return true;
  //   } catch (error) {
  //     console.log("Unable to get cart items");
  //     console.log(error);
  //     return false;
  //   }
  // };

  favoriteSong = async (track) => {
    // this.props.setLoader(true);

    if (!this.isFavorited(track)) {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/favourties/track/${track.id}`,
      //     {
      //       method: "post",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // alert("Song favorited!");
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to favorite track");
      // }
      await addToFavorite(track);
    } else {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/favourties/track/${track.id}`,
      //     {
      //       method: "delete",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // alert("Song unfavorited!");
      // } catch (error) {
      //   console.log(error);
      //   console.log("Unable to unfavorite track");
      // }
      await removeFromFavorite(track);
    }

    // await this.getFavorites();
    await fetchFavorites(true);

    // this.props.setLoader(false);
  };

  addToCartSong = async (track) => {
    // this.props.setLoader(true);

    if (!this.isInCart(track)) {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/cart/track/${track.id}`,
      //     {
      //       method: "post",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   // alert("Song added to cart!");
      //   NotificationManager.success("Song added to cart");
      //   this.props.incrementCartCount();
      // } catch (error) {
      //   console.log(error);
      //   alert("Unable to add song to cart");
      // }
      await addToCart(track);
    } else {
      // try {
      //   var response = await requestWithBearer(
      //     `/tracks-service/api/cart/track/${track.id}`,
      //     {
      //       method: "delete",
      //       headers: { "Content-Type": "application/json" },
      //       // body: userData,
      //     }
      //   );

      //   var requestData = response.data;
      //   console.log(requestData);

      //   this.props.decrementCartCount();
      //   // alert("Song removed from cart!");
      // } catch (error) {
      //   console.log(error);
      //   console.log("Unable to remove song from cart");
      // }
      await removeFromCart(track);
    }

    // await this.getCartItems();
    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isFavorited = (song) => {
    if (!this.props.favorites) return false;

    for (var i = 0; i < this.props.favorites.length; i++) {
      if (this.props.favorites[i].id === song.id) return true;
    }

    return false;
  };

  isInCart = (song) => {
    if (!this.props.cart) return false;

    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].id === song.id) return true;
    }

    return false;
  };

  // playSong = (song) => {
  //     console.log(song);
  //     // this.audioInstance.clear();
  //     var playingSong = {
  //         name: song.title,
  //         musicSrc: song.audioFile, //"/assets/audio/ringtone-5.mp3",
  //         cover: song.imageUrl,
  //     };
  //     this.setState({ playingSong });
  // };

  render() {
    let user = this.context.user;

    if (!user) return null;

    return (
      <>
        <div className="banner bg-favorites"></div>

        <div className="main-container" id="appRoute">
          <div className="row align-items-end">
            <span className="col-6 font-weight-bold">
              {this.props.favorites.length} Results
            </span>
            <div className="col-6 ml-auto">
              <form
                action="#"
                className="form-inline justify-content-end invisible"
              >
                <label className="mr-2" htmlFor="filter2">
                  Filter By:
                </label>
                <select className="custom-select mr-sm-2" id="filter2">
                  <option selected>Popular</option>
                  <option value="1">A-Z</option>
                  <option value="2">Z-A</option>
                </select>
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="section custom-list">
            {this.props.favorites.map((song) => (
              <div className="custom-list--item flex-nowrap">
                <div
                  className="text-dark custom-card--inline"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.playSong(song);
                  }}
                >
                  <div className="custom-card--inline-img">
                    <img
                      src={song.imageUrl}
                      alt=""
                      className="card-img--radius-sm"
                    />
                  </div>

                  <div className="custom-card--inline-desc">
                    <p className="text-truncate mb-0">{song.title}</p>
                    <p className="text-truncate text-muted font-sm mb-0">
                      {song.description}
                    </p>
                  </div>
                </div>
                <ul className="custom-card--labels d-flex ml-auto">
                  <li>
                    <a
                      className="badge badge-pill badge-danger"
                      onClick={() => this.favoriteSong(song)}
                      style={{
                        border: "solid 1px red",
                        color: this.isFavorited(song) ? "white" : "red",
                        backgroundColor: this.isFavorited(song)
                          ? "red"
                          : "white",
                      }}
                    >
                      <i className="la la-heart"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className={`badge badge-pill ${
                        this.isInCart(song) ? "badge-light" : "badge-success"
                      }`}
                      onClick={() => this.addToCartSong(song)}
                      style={{
                        color: this.isInCart(song) ? "black" : "white",
                        padding: "0 0.5rem",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "5.75rem",
                        cursor: "pointer",
                      }}
                    >
                      {/* <i className="la-shopping-cart"></i> */}
                      <i className="ion-ios-cart"></i>&nbsp;
                      {!this.isInCart(song) ? "Add To Cart" : "In Cart"}
                    </a>
                  </li>
                  {/* <li>05:03</li> */}
                  {/* <li className="dropleft">
                                            <a href="#" className="btn btn-icon-only btn-secondary p-0 w-auto h-auto"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="la la-ellipsis-h"></i>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link favorite">
                                                        <i className="la la-heart-o"></i>
                                                        <span>Favorite</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-plus"></i>
                                                        <span>Add to Playlist</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-download"></i>
                                                        <span>Download</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="#" className="dropdown-link">
                                                        <i className="la la-share-alt"></i>
                                                        <span>Share</span>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a href="song-details.html" className="dropdown-link">
                                                        <i className="la la-info-circle"></i>
                                                        <span>Song Info</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li> */}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <NotificationContainer />

        {/* <Waveform song={this.state.playingSong} /> */}
      </>
    );
  }
}

MyFavorites.contextType = UserContext;


const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    favorites: state.favorites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setCart: (cart) => dispatch(setCart(cart)),
    // setFavorites: (favorites) => dispatch(setFavorites(favorites)),
  };
};

export default connect(mapStateToProps, null)(MyFavorites);