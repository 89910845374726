import React from "react";
import { UserContext } from "../../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../../firebase/Firebase";
import { setSubState, retryCall } from "../../../common/common";
import ReactJkMusicPlayer from "react-jinke-music-player";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  setCart,
  setFavorites,
  setPopularSongs,
} from "../../../redux/actions/actions";
import { connect } from "react-redux";
import {
  addToCart,
  addToFavorite,
  fetchCart,
  fetchFavorites,
  fetchPopularSongs,
  removeFromCart,
  removeFromFavorite,
} from "../../../api/TracksApiController";

class Popular extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      songsInfo: {},
      favorites: [],
      cart: [],
      selectedTrack: null,
      showEditTrack: false,
      playingSong: {},
      // showToast: false,
    };

    this.audioInstance = null;
  }

  componentDidMount() {
    // Only fetch once when website is openend instead of everytime genre is loaded
    // this.getAllTracks();
    fetchPopularSongs();
    // if (this.context.user) {
    //   // retryCall(this.getFavorites, 3, 2000);
    //   // retryCall(this.getCartItems, 3, 2000);
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

  // getAllTracks = async () => {
  //   try {
  //     var response = await requestWithoutBearer(
  //       `/tracks-service/api/tracks/hotPick`,
  //       {
  //         method: "get",
  //         headers: { "Content-Type": "application/json" },
  //         // body: newTrackData,
  //       }
  //     );

  //     var requestData = response.data;
  //     console.log(requestData);

  //     var data = {};
  //     if (requestData) {
  //       data.songsInfo = requestData;
  //       if (requestData.content) data.songs = [...requestData.content];
  //     }
  //     this.setState(data);
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Unable to get popular tracks");
  //   }
  // };

  // getFavorites = async () => {
  //   try {
  //     var response = await requestWithBearer(`/tracks-service/api/favourties`, {
  //       method: "get",
  //       headers: { "Content-Type": "application/json" },
  //       // body: newTrackData,
  //     });

  //     var requestData = response.data;
  //     console.log(requestData);

  //     if (requestData.items) this.setState({ favorites: requestData.items });
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Unable to get favorites");
  //   }
  // };

  getCartItems = async () => {
    try {
      var response = await requestWithBearer(`/tracks-service/api/cart`, {
        method: "get",
        headers: { "Content-Type": "application/json" },
        // body: newTrackData,
      });

      var requestData = response.data;
      console.log(requestData);

      if (requestData.items) {
        this.setState({ cart: requestData.items });
        this.props.setCartCount(requestData.items.length);
      }
    } catch (error) {
      console.log(error);
      // alert("Unable to get cart items");
    }
  };

  favoriteSong = async (song) => {
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isFavorited(song)) {
      addToFavorite(song);
    } else {
      removeFromFavorite(song);
    }
  };

  addToCartSong = async (song) => {
    // this.props.setLoader(true);
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isInCart(song)) {
      // try {
      //     var response = await requestWithBearer(
      //         `/tracks-service/api/cart/track/${track.id}`,
      //         {
      //             method: "post",
      //             headers: { "Content-Type": "application/json" },
      //             // body: userData,
      //         }
      //     );

      //     var requestData = response.data;
      //     console.log(requestData);

      //     // this.setState({showToast: true});
      //     NotificationManager.success("Song added to cart");
      //     this.props.incrementCartCount();
      //     // alert("Song added to cart!");
      // } catch (error) {
      //     console.log(error);
      //     alert("Unable to add song to cart");
      // }
      addToCart(song);
    } else {
      // try {
      //     var response = await requestWithBearer(
      //         `/tracks-service/api/cart/track/${track.id}`,
      //         {
      //             method: "delete",
      //             headers: { "Content-Type": "application/json" },
      //             // body: userData,
      //         }
      //     );

      //     var requestData = response.data;
      //     console.log(requestData);

      //     this.props.decrementCartCount();
      //     // alert("Song removed from cart!");
      // } catch (error) {
      //     console.log(error);
      //     alert("Unable to remove song from cart");
      // }
      removeFromCart(song);
    }

    // await this.getCartItems();
    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isFavorited = (song) => {
    // if (!this.props.favorites) return false;

    let songIndex = this.props.favorites.findIndex(
      (item) => item.id === song.id
    );

    if (songIndex === -1) {
      return false;
    }
    return true;

    // for (var i = 0; i < this.props.favorites.length; i++) {
    //     if (this.props.favorites[i].id === song.id) return true;
    // }

    // return false;
  };

  isInCart = (song) => {
    if (!this.props.cart) return false;

    let songIndex = this.props.cart.findIndex((item) => item.id === song.id);

    if (songIndex === -1) {
      return false;
    }
    return true;

    // for (var i = 0; i < this.state.cart.length; i++) {
    //     if (this.state.cart[i].id === song.id) return true;
    // }

    // return false;
  };

  // playSong = (song) => {
  //     // this.audioInstance.clear();
  //     var playingSong = {
  //         name: song.title,
  //         musicSrc: song.audioFile,   //"/assets/audio/ringtone-5.mp3",
  //         cover: song.imageUrl,
  //     };
  //     this.setState({ playingSong });

  //     this.props.playSong(playingSong);
  // }

  render() {
    return (
      <div className="section">
        <div className="heading">
          <div className="d-flex flex-wrap align-items-end">
            <div className="flex-grow-1">
              <h4>Popular Tracks</h4>
              <p>Listen to popular tracks</p>
            </div>
            {/* <a href="songs.html" className="btn btn-sm btn-pill btn-air btn-primary">View All</a> */}
          </div>
          <hr />
        </div>

        <div className="custom-card__cards-list mb-4">
          {this.props.popularSongs &&
            this.props.popularSongs.map((song) => (
              <div className="custom-card" key={song.id}>
                <div className="custom-card--img">
                  <div className="custom-card--info">
                    <ul className="custom-card--labels d-flex">
                      <li>
                        <button
                          className="btn btn-pill btn-air btn-danger btn-icon-only"
                          onClick={() => this.favoriteSong(song)}
                          style={{
                            color: this.isFavorited(song) ? "white" : "#f11717",
                            background: this.isFavorited(song)
                              ? "#f11717"
                              : "white",
                          }}
                        >
                          <i className="la la-heart"></i>
                        </button>
                      </li>
                      {/* style={{ color: this.isFavorited(song) ? "white" : "grey" }}
                                                style={{ color: this.isInCart(song) ? "white" : "grey" }} */}
                      {/* <li>
                                                    <button className="btn btn-pill btn-air btn-success btn-icon-only"
                                                        onClick={() => this.addToCartSong(song)}
                                                        style={{ color: !this.isInCart(song) ? "#00c746" : "white", background: !this.isInCart(song) ? "white" : "#00c746" }}>
                                                        <i className="la la-shopping-cart"></i>
                                                    </button>
                                                </li> */}
                    </ul>
                  </div>
                  <a
                    href="#"
                    className="external"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.playSong(song);
                    }}
                  >
                    <div
                      alt={song.title}
                      className="card-img--radius-lg"
                      style={{
                        backgroundImage: `url(${song.imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "7.5rem",
                      }}
                    ></div>
                    {/* <img
                      src={song.imageUrl}
                      alt={song.title}
                      className="card-img--radius-lg"
                    /> */}
                  </a>
                </div>

                <a
                  href="#"
                  className="custom-card--link mt-2 d-flex justify-content-between"
                >
                  <div>
                    <h6>{song.title}</h6>
                    <p>${song.price?.value}</p>
                  </div>
                  {/* <button className="btn btn-pill btn-air btn-success btn-icon-only" */}
                  <button
                    className={`btn badge-pill ${
                      this.isInCart(song) ? "btn-light" : "btn-success"
                    }`}
                    onClick={() => this.addToCartSong(song)}
                    // style={{ color: !this.isInCart(song) ? "#00c746" : "white", background: !this.isInCart(song) ? "white" : "#00c746" }}
                    style={{ height: "10%" }}
                  >
                    {/* <i className="la-shopping-cart"></i> */}
                    <i className="ion-ios-cart"></i>
                    {!this.isInCart(song) ? " Add To Cart" : " In Cart"}
                  </button>
                </a>
              </div>
            ))}
          <div style={{ height: "4rem" }}></div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

Popular.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    popularSongs: state.popularSongs,
    cart: state.cart,
    // loadingCart: state.loadingCart,
    favorites: state.favorites,
    // loadingFavorites: state.loadingFavorites,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setPopularSongs: (popularSongs) => dispatch(setPopularSongs(popularSongs)),
//     setCart: (cart) => dispatch(setCart(cart)),
//     // setLoadingCart: (cart) => dispatch(setLoadingCart(cart)),
//     setFavorites: (favorites) => dispatch(setFavorites(favorites)),
//     // setLoadingFavorites: (favorites) =>
//     //     dispatch(setLoadingFavorites(favorites)),
//   };
// };

export default connect(mapStateToProps, null)(Popular);
