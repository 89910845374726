import { ACTION_TYPES } from "../constants/action-types";

export function setProducer(payload) {
  return { type: ACTION_TYPES.SET_PRODUCER, payload };
}

export function setSongs(payload) {
  return { type: ACTION_TYPES.SET_SONGS, payload };
}
export function setSongsPage(payload) {
  return { type: ACTION_TYPES.SET_SONGS_PAGE, payload };
}
export function setTotalSongsPages(payload) {
  return { type: ACTION_TYPES.SET_TOTAL_SONGS_PAGES, payload };
}
export function setSongsSorting(payload) {
  return { type: ACTION_TYPES.SET_SONGS_SORTING, payload };
}
// export function setSongsFilterDir(payload) {
//   return { type: ACTION_TYPES.SET_SONGS_FILTER_DIR, payload };
// }

export function setGenres(payload) {
  return { type: ACTION_TYPES.SET_GENRES, payload };
}
export function setSelectedGenre(payload) {
  return { type: ACTION_TYPES.SET_SELECTED_GENRE, payload };
}
export function setSelectedGenrePage(payload) {
  return { type: ACTION_TYPES.SET_SELECTED_GENRE_PAGE, payload };
}

export function setCart(payload) {
  return { type: ACTION_TYPES.SET_CART, payload };
}
export function addLoadingCart(payload) {
  return { type: ACTION_TYPES.ADD_LOADING_CART, payload };
}
export function removeLoadingCart(payload) {
  return { type: ACTION_TYPES.REMOVE_LOADING_CART, payload };
}

export function setFavorites(payload) {
  return { type: ACTION_TYPES.SET_FAVORITES, payload };
}
export function addLoadingFavorites(payload) {
  return { type: ACTION_TYPES.ADD_LOADING_FAVORITES, payload };
}
export function removeLoadingFavorites(payload) {
  return { type: ACTION_TYPES.REMOVE_LOADING_FAVORITES, payload };
}

export function setLatestSongs(payload) {
  return { type: ACTION_TYPES.SET_LATEST_SONGS, payload };
}

export function setPopularSongs(payload) {
  return { type: ACTION_TYPES.SET_POPULAR_SONGS, payload };
}

export function setSearchTerm(payload) {
  return { type: ACTION_TYPES.SET_SEARCH_TERM, payload };
}

export function setSearchType(payload) {
  return { type: ACTION_TYPES.SET_SEARCH_TYPE, payload };
}

export function setSidebarOpen(payload) {
  return { type: ACTION_TYPES.SET_SIDEBAR_OPEN, payload };
}
