import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import ReactJkMusicPlayer from "react-jinke-music-player";
// import 'react-jinke-music-player/assets/index.css';
// import './MusicPlayer.css';
// import { Toast } from 'react-bootstrap';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// import Wavesurfer from 'react-wavesurfer';
import Waveform from "../Waveform/Waveform";
// require("wavesurfer.js");

import { connect } from "react-redux";
import {
  setSearchTerm,
  setSearchType,
  setSongs,
  setSongsSorting,
  setSongsPage,
} from "../../redux/actions/actions";
import {
  addToCart,
  addToFavorite,
  fetchCart,
  fetchFavorites,
  fetchSongs,
  removeFromCart,
  removeFromFavorite,
} from "../../api/TracksApiController";
import ReactPaginate from "react-paginate";

class Songs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      songsInfo: {},
      favorites: [],
      cart: [],
      sortType: null,
      sortDir: "asc",
      // selectedTrack: null,
      // showEditTrack: false,
      // playingSong: {},
      // showToast: false,
      // isPlaying: false,
      // playPos: 0,
      // searchTerm: !window.searchTerm || !window.useSearch ? null : window.searchTerm,
    };

    // console.log(window.searchTerm);
    // console.log(window.useSearch);
    console.log(this.props.searchTerm);
    console.log(this.props.searchType);

    this.audioInstance = null;
  }

  componentDidMount() {
    this.props.setPlayerVisibility(true);

    this.getAllTracks(
      this.props.searchTerm,
      this.props.searchType,
      this.props.songsSorting,
      this.props.songsPage,
      false // true
    );

    // if (this.context.user) {
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

  componentWillUnmount() {
    this.props.setSearchTerm(null);
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.searchTerm !== this.props.searchTerm ||
      newProps.searchType !== this.props.searchType ||
      newProps.songsSorting !== this.props.songsSorting
    ) {
      this.props.setSongsPage(0);
      this.getAllTracks(
        newProps.searchTerm,
        newProps.searchType,
        newProps.songsSorting,
        0,
        true
      );
    }

    // if (this.context.user) {
    //   fetchFavorites();
    //   fetchCart();
    // }
  }

  onInputChange = (event) => {
    if (event) event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    // this.setState({ [event.target.name]: value });
    this.props.setSongsSorting(value);
    // this.getAllTracks(
    //   this.props.searchTerm,
    //   this.props.searchType,
    //   value,
    //   this.props.songsPage,
    //   true
    // );
  };

  getAllTracks = async (
    searchTerm,
    searchType,
    sortBy,
    pageNumber,
    forceFetch = false
  ) => {
    this.props.setLoader(true);

    await fetchSongs(
      {
        searchTerm,
        searchType,
        pageNumber,
        sortBy,
      },
      forceFetch
    );

    this.props.setLoader(false);
  };

  favoriteSong = async (track) => {
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isFavorited(track)) {
      await addToFavorite(track);
    } else {
      await removeFromFavorite(track);
    }

    // Refresh favorites list
    await fetchFavorites(true);
  };

  addToCartSong = async (track) => {
    // this.props.setLoader(true);
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isInCart(track)) {
      await addToCart(track);
    } else {
      await removeFromCart(track);
    }

    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isFavorited = (song) => {
    // if (!this.props.favorites) return false;

    for (var i = 0; i < this.props.favorites.length; i++) {
      if (this.props.favorites[i].id === song.id) return true;
    }

    return false;
  };

  isInCart = (song) => {
    // if (!this.props.cart) return false;

    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].id === song.id) return true;
    }

    return false;
  };

  handlePageChange = (event) => {
    this.props.setSongsPage(event.selected);
    this.getAllTracks(
      this.props.searchTerm,
      this.props.searchType,
      this.props.songsSorting,
      event.selected,
      true
    );
  };

  render() {
    let user = this.context.user;

    return (
      <>
        <div className="banner bg-catalogue"></div>

        <div className="main-container" id="appRoute">
          <div className="row align-items-end">
            {/* <span className="col-6 font-weight-bold">{this.state.songsInfo.totalElements} Results</span> */}
            <div className="col-6 ml-auto">
              <form action="#" className="form-inline justify-content-end">
                <label className="mr-2" htmlFor="filter2">
                  Sorted By:
                </label>
                <select
                  className="custom-select mr-sm-2"
                  id="filter2"
                  value={this.props.songsSorting}
                  onChange={this.onInputChange}
                >
                  <option value="createdDate,asc" selected>
                    Newest
                  </option>
                  <option value="title,asc">A-Z</option>
                  <option value="title,desc">Z-A</option>
                  <option value="price,asc">Price: Low to High</option>
                  <option value="price,desc">Price: High to Low</option>
                </select>
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="row section">
            {this.props.songs &&
              this.props.songs.map((song) => (
                <div className="col-xl-3 col-lg-4 col-sm-6 pb-4" key={song.id}>
                  <div className="custom-card">
                    <div className="custom-card--img">
                      <div className="custom-card--info">
                        <ul className="custom-card--labels d-flex">
                          <li>
                            <button
                              className="btn btn-pill btn-air btn-danger btn-icon-only"
                              onClick={() => this.favoriteSong(song)}
                              style={{
                                color: this.isFavorited(song)
                                  ? "white"
                                  : "#f11717",
                                background: this.isFavorited(song)
                                  ? "#f11717"
                                  : "white",
                              }}
                            >
                              <i className="la la-heart"></i>
                            </button>
                          </li>
                          {/* style={{ color: this.isFavorited(song) ? "white" : "grey" }}
                                                    style={{ color: this.isInCart(song) ? "white" : "grey" }} */}
                          {/* <li>
                                                        <button className="btn btn-pill btn-air btn-success btn-icon-only"
                                                            onClick={() => this.addToCartSong(song)}
                                                            style={{ color: !this.isInCart(song) ? "#00c746" : "white", background: !this.isInCart(song) ? "white" : "#00c746" }}>
                                                            <i className="la la-shopping-cart"></i>
                                                        </button>
                                                    </li> */}
                        </ul>
                        {/* <div className="dropdown dropdown-icon">
                                                    <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="ion-md-more"></i>
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link favorite">
                                                                <i className="la la-heart-o"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-plus"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-download"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-share-alt"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="song-details.html" className="dropdown-link">
                                                                <i className="la la-info-circle"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                      </div>
                      <a
                        href="#"
                        className="external"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.playSong(song);
                        }}
                      >
                        <div
                          alt={song.title}
                          className="card-img--radius-lg"
                          style={{
                            backgroundImage: `url(${song.imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "12.25rem",
                          }}
                        ></div>
                        {/* <img
                          src={song.imageUrl}
                          alt={song.title}
                          className="card-img--radius-lg"
                        /> */}
                      </a>
                    </div>

                    <a
                      // href="#"
                      className="custom-card--link mt-2 d-flex justify-content-between"
                    >
                      <div>
                        <h6>{song.title}</h6>
                        <p>${song.price?.value}</p>
                      </div>
                      {/* <button className="btn btn-pill btn-air btn-success btn-icon-only" */}
                      <button
                        className={`btn badge-pill ${
                          this.isInCart(song) ? "btn-light" : "btn-success"
                        }`}
                        onClick={() => this.addToCartSong(song)}
                        // style={{ color: !this.isInCart(song) ? "#00c746" : "white", background: !this.isInCart(song) ? "white" : "#00c746" }}
                        style={{ height: "10%" }}
                      >
                        {/* <i className="la la-shopping-cart"></i> */}
                        <i className="ion-ios-cart"></i>
                        {!this.isInCart(song) ? " Add To Cart" : " In Cart"}
                      </button>
                    </a>
                  </div>
                </div>
              ))}

            <div
              style={{
                width: "100%",
                display: this.props.totalSongsPages > 1 ? "flex" : "none",
                justifyContent: "center",
              }}
            >
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={this.props.totalSongsPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={this.props.songsPage}
              />
            </div>
            {/* <Waveform song={this.state.playingSong} /> */}

            <NotificationContainer />
          </div>
        </div>
      </>
    );
  }
}

Songs.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    songs: state.songs,
    cart: state.cart,
    favorites: state.favorites,
    searchTerm: state.searchTerm,
    searchType: state.searchType,
    songsPage: state.songsPage,
    totalSongsPages: state.totalSongsPages,
    songsSorting: state.songsSorting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSongs: (songs) => dispatch(setSongs(songs)),
    setSearchTerm: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
    setSearchType: (searchType) => dispatch(setSearchType(searchType)),
    setSongsPage: (songsPage) => dispatch(setSongsPage(songsPage)),
    setSongsSorting: (songsSorting) => dispatch(setSongsSorting(songsSorting)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Songs);
