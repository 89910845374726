import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Slider from "react-slick";
import { fetchGenres } from "../../../api/TracksApiController";

import { connect } from "react-redux";
import { setGenres, setSelectedGenre } from "../../../redux/actions/actions";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={`btn-next btn btn-pill btn-air btn-default btn-icon-only `}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <i className="la la-angle-right"></i>
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={`btn-prev btn btn-pill btn-air btn-default btn-icon-only`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <i className="la la-angle-left"></i>
        </button>
    );
}

class GenresMini extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // genres: [],
        };
    }

    carouselSettings = (itemXl, itemLg, itemMd, itemSm) => {
        return {
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: itemXl,
            slidesToScroll: 1,
            speed: 1000,
            prevArrow: <SamplePrevArrow />,
            nextArrow: <SampleNextArrow />,
            autoplay: false,

            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: itemLg,
                    },
                },

                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: itemMd,
                    },
                },

                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: itemSm,
                    },
                },

                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                    },
                },
            ],
        };
    };

    componentDidMount() {
        fetchGenres();
    }

    openGenre = (genre) => {
        console.log(genre);
        this.props.setSelectedGenre(genre);
        this.props.history.push("/genres");
    };

    render() {
        const settings = this.carouselSettings(5, 4, 3, 2);
        return (
            <div className="section">
                <div className="heading">
                    <div className="d-flex flex-wrap align-items-end">
                        <div className="flex-grow-1">
                            <h4>Genres</h4>
                            <p>Select your genre</p>
                        </div>
                        <NavLink
                            to="/genres"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <div className="btn btn-sm btn-pill btn-air btn-primary">
                                View All
                            </div>
                        </NavLink>
                    </div>
                    <hr />
                </div>

                <Slider className={"carousel-item-5 arrow-pos-1"} {...settings}>
                    {this.props.genres &&
                        this.props.genres.map((genre) => (
                            <div className="custom-card" key={genre.id}>
                                <div className="custom-card--img">
                                    <a
                                        href="#"
                                        onClick={() => this.openGenre(genre)}
                                    >
                                        <img
                                            src={
                                                !genre.imageUrl
                                                    ? "/assets/images/background/horizontal/1.jpg"
                                                    : genre.imageUrl
                                            }
                                            alt={genre.title}
                                            className="card-img--radius-md"
                                        />
                                        {/* <span className="bg-blur">{genre.title}</span> */}
                                    </a>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        genres: state.genres,
        // selectedGenre: state.selectedGenre,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setGenres: (genres) => dispatch(setGenres(genres)),
        setSelectedGenre: (selectedGenre) =>
            dispatch(setSelectedGenre(selectedGenre)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GenresMini));
