import React from "react";
import TagsInput from "react-tagsinput";
import { UserContext } from "../../auth/providers";
import { DOWNLOAD_TYPES } from "../../common/constants";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import "./react-tagsinput.css";

const TAG_SEPARATOR = ";";

export default class EditTrack extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addTrack: {
        songName: "",
        songPrice: 0,
        genreId: "default",
      },
      tagsList: [],
      // tncAccepted: false,
      // genres: this.props.genres,
    };

    this.fileToUploadCount = null;

    if (this.props.trackDetails) {
      this.state.addTrack = { ...this.props.trackDetails };
      if (this.state.addTrack.imageUrl) {
        var splitUrl = this.state.addTrack.imageUrl.split("/");
        if (splitUrl.length > 0) {
          this.state.addTrack.songImage = {
            name: splitUrl[splitUrl.length - 1],
          };
        }
      }
      //   if (this.state.addTrack.assertUrl) {
      //     var splitUrl = this.state.addTrack.assertUrl.split("/");
      //     if (splitUrl.length > 0) {
      //       this.state.addTrack.songFile = {
      //         name: splitUrl[splitUrl.length - 1],
      //       };
      //     }
      //   }

      if (this.state.addTrack.price !== null) {
        console.log(this.state.addTrack.price);

        if (
          !this.state.addTrack.price.value ||
          this.state.addTrack.price.value === 0
        ) {
          console.log("Free");
          this.state.addTrack.eventType = "free";
        } else {
          console.log("Paid");
          this.state.addTrack.eventType = "paid";
          this.state.addTrack.songPrice = this.state.addTrack.price.value;
        }
      } else {
        this.state.addTrack.eventType = "free";
      }

      if (this.state.addTrack.genre !== null) {
        if (this.state.addTrack.genre.id != null) {
          this.state.addTrack.genreId = this.state.addTrack.genre.id;
        }
      }

      if (this.state.addTrack.tags)
        this.state.tagsList = this.state.addTrack.tags.split(TAG_SEPARATOR);

      Object.keys(DOWNLOAD_TYPES).forEach((downloadTypeKey) => {
        let downloadType = DOWNLOAD_TYPES[downloadTypeKey];
        if (this.state.addTrack[downloadType.obj]) {
          var splitUrl = this.state.addTrack[downloadType.obj].split("/");
          if (splitUrl.length > 0) {
            this.state.addTrack[downloadTypeKey] = {
              name: splitUrl[splitUrl.length - 1],
            };
          }
        }
      });
    }
  }

  componentDidMount() {
    // Only fetch once when website is openend instead of everytime genre is loaded
    // this.getMyTracks();
    // this.getGenres();
  }

  setSubState = (subState, keyVal) => {
    this.setState((prevState) => {
      if (prevState[subState] != null)
        return {
          [subState]: {
            ...prevState[subState],
            ...keyVal,
          },
        };
      else
        return {
          [subState]: {
            ...keyVal,
          },
        };
    });
  };

  onInputChange = (event) => {
    // if (event)
    //     event.preventDefault();
    let value = event.target.value;
    // this.setState({ [event.target.name]: value });
    this.setSubState("addTrack", { [event.target.name]: value });
  };

  onLoadFile = (event) => {
    if (!event.target.files[0])
      // cancel clicked
      return;

    let value = event.target.value;
    let file = event.target.files[0];

    this.setSubState("addTrack", { [event.target.name]: file });
  };

  // uploadImage = async () => {
  //     if (!this.state.isModified || !this.state.data.file)
  //         return;

  //     this.props.setLoader(true);

  //     try {
  //         var data = new FormData();
  //         data.append('image', this.state.data.file);

  //         var uploadResponse = await requestWithBearer('/user-service/api/user/profile/image', {
  //             method: 'post',
  //             headers: { 'Content-Type': 'multipart/form-data' },
  //             body: data,
  //         });
  //         console.log(uploadResponse);

  //         // await updateUserProfile();
  //     }
  //     catch (error) {
  //         console.log(error);
  //         alert("Unable to update profile image");
  //     }

  //     this.props.setLoader(false);
  // }

  uploadImage = async (id, file, progressFunc) => {
    if (!file) return null;

    // this.props.setLoader(true);

    try {
      var data = new FormData();
      data.append("image", file);

      var uploadResponse = await requestWithBearer(
        `/tracks-service/api/track/${id}/image`,
        {
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          body: data,
        },
        {
          onUploadProgress: progressFunc,
        }
      );
      console.log(uploadResponse);

      // await updateUserProfile();

      return uploadResponse;
    } catch (error) {
      console.log(error);
      alert("Unable to update track image");
      return null;
    }

    // this.props.setLoader(false);
  };

  uploadFile = async (id, fileType, file, progressFunc) => {
    if (!file) return null;

    // this.props.setLoader(true);

    try {
      var data = new FormData();
      data.append("asset", file);

      var uploadResponse = await requestWithBearer(
        // `/tracks-service/api/track/${id}/uploadAsset`,
        `/tracks-service/api/track/${id}/${fileType}`,
        {
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          body: data,
        },
        {
          onUploadProgress: progressFunc,
        }
      );
      console.log(uploadResponse);

      return uploadResponse;
    } catch (error) {
      console.log(error);
      alert("Unable to update track asset");
      return null;
    }

    // this.props.setLoader(false);
  };

  onFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!this.state.addTrack.title) {
      alert("Invalid Song Name");
      return;
    }
    if (!this.state.addTrack.description) {
      alert("Invalid Description");
      return;
    }

    if (!this.state.addTrack.eventType) {
      alert("Invalid Song Price Type");
      return;
    }
    if (!this.state.addTrack.genreId) {
      alert("Invalid Genre");
      return;
    }
    if (!this.state.addTrack.bpm) {
      alert("Invalid BPM");
      return;
    }

    // if (!this.state.addTrack.songImage || !this.state.addTrack.songImage.size) {
    //     alert("Invalid Song Image");
    //     return;
    // }
    // if (!this.state.addTrack.songFile || !this.state.addTrack.songFile.size) {
    //     alert("Invalid Song File");
    //     return;
    // }

    this.props.setLoader(true, "Uploading Files (0%)");

    var tagsJoined = this.state.tagsList.join(TAG_SEPARATOR);

    var newTrackData = {
      title: this.state.addTrack.title,
      description: this.state.addTrack.description,
      genreId: this.state.addTrack.genreId,
      beatsPerMinute: this.state.addTrack.bpm,
      tags: tagsJoined,
      tag: tagsJoined,
    };
    var songPrice = 0;
    if (
      this.state.addTrack.eventType === "paid" &&
      this.state.addTrack.songPrice != null
    ) {
      songPrice = parseInt(this.state.addTrack.songPrice);
      if (isNaN(songPrice)) {
        alert("Invalid Song Price");
        return;
      }
    }
    console.log(this.state.addTrack.price);
    console.log(songPrice);

    newTrackData.price = {
      value: songPrice,
      currency: "USD",
      // currency: {
      //     currencyCode: "USD",
      //     defaultFractionDigits: 0,
      //     numericCode: 0,
      //     symbol: "$",
      //     displayName: "USD",
      //     numericCodeAsString: "",
      // }
    };

    console.log(newTrackData);

    try {
      var response = null;
      if (this.state.addTrack.id != null) {
        // Only call if text data has been modified?
        var response = await requestWithBearer(
          `/tracks-service/api/track/${this.state.addTrack.id}`,
          {
            method: "put",
            headers: { "Content-Type": "application/json" },
            body: newTrackData,
          }
        );
      } else {
        var response = await requestWithBearer(`/tracks-service/api/track`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: newTrackData,
        });
      }

      var requestData = response.data;
      console.log(requestData);

      if (requestData && requestData.id != null) {
        let downloadTypeKeys = Object.keys(DOWNLOAD_TYPES);

        // Count number of files to upload to calculate percentage to show
        this.fileToUploadCount = 0;
        this.fileUplaodedCount = 0;
        if (
          this.state.addTrack.songImage &&
          this.state.addTrack.songImage.size != null
        )
          this.fileToUploadCount++;

        for (let i = 0; i < downloadTypeKeys.length; i++) {
          let downloadTypeKey = downloadTypeKeys[i];
          if (
            this.state.addTrack[downloadTypeKey] &&
            this.state.addTrack[downloadTypeKey].size != null
          )
            this.fileToUploadCount++;
        }
        console.log("fileToUplaodCount");
        console.log(this.fileToUploadCount);

        if (
          this.state.addTrack.songImage &&
          this.state.addTrack.songImage.size != null
        ) {
          await this.uploadImage(
            requestData.id,
            this.state.addTrack.songImage,
            (progressEvent) => {
              var ratioCompleted = progressEvent.loaded / progressEvent.total;
              var percentPerFile = 100 / this.fileToUploadCount;
              var totalPercentCompleted = Math.round(
                percentPerFile * ratioCompleted
              );
              console.log(
                this.fileUplaodedCount +
                  " - " +
                  ratioCompleted +
                  " - " +
                  totalPercentCompleted
              );

              this.props.setLoader(
                true,
                `Uploading Files (${totalPercentCompleted}%)`
              );
            }
          );
          this.fileUplaodedCount++;
        }

        // if (this.state.addTrack.songFile && this.state.addTrack.songFile.size != null)
        //     this.uploadFile(requestData.id, this.state.addTrack.songFile);

        for (let i = 0; i < downloadTypeKeys.length; i++) {
          let downloadTypeKey = downloadTypeKeys[i];
          if (
            this.state.addTrack[downloadTypeKey] &&
            this.state.addTrack[downloadTypeKey].size != null
          ) {
            await this.uploadFile(
              requestData.id,
              DOWNLOAD_TYPES[downloadTypeKey].url,
              this.state.addTrack[downloadTypeKey],
              (progressEvent) => {
                var ratioCompleted = progressEvent.loaded / progressEvent.total;
                var percentPerFile = 100 / this.fileToUploadCount;
                var totalPercentCompleted = Math.round(
                  percentPerFile * this.fileUplaodedCount +
                    percentPerFile * ratioCompleted
                );
                console.log(
                  this.fileUplaodedCount +
                    " - " +
                    ratioCompleted +
                    " - " +
                    totalPercentCompleted
                );

                this.props.setLoader(
                  true,
                  `Uploading Files (${totalPercentCompleted}%)`
                );
              }
            );
            this.fileUplaodedCount++;
          }
        }
      }

      await this.props.refreshTracks();

      this.props.setLoader(false);

      this.props.close();
    } catch (error) {
      console.log(error);
      alert("Unable to update track");
    }

    this.props.setLoader(false);
  };

  // renderTnC = () => {
  //   return (
  //     <>
  //       <div className="card" style={{ width: "60%" }}>
  //         <div className="card-header d-flex justify-content-between">
  //           <h6 className="card-title mb-0">Terms and Conditions</h6>
  //           <a
  //             style={{ color: "black", fontSize: "1rem", cursor: "pointer" }}
  //             onClick={this.props.close}
  //           >
  //             <i className="la la-times"></i>
  //           </a>
  //         </div>
  //         <div className="card-body">
  //           <form
  //             onSubmit={(event) => {
  //               event.preventDefault();
  //               this.setState({ tncAccepted: true });
  //             }}
  //           >
  //             <iframe
  //               src={`/assets/pdf/MusicaFresh_Terms_and_Conditions.pdf?#scrollbar=1&toolbar=0&navpanes=0`}
  //               style={{
  //                 height: "50vh",
  //                 width: "100%",
  //                 marginBottom: "1.5rem",
  //               }}
  //             />
  //             <input
  //               type="checkbox"
  //               id="accept"
  //               style={{ appearance: "revert", margin: "revert" }}
  //               required
  //             ></input>
  //             <label htmlFor="accept">
  //               {" "}
  //               I accept Musicafresh Terms and Conditions
  //             </label>
  //             <br />
  //             <div className="w-100 mt-2 text-center">
  //               <button type="submit" className="btn btn-brand btn-air">
  //                 Submit
  //               </button>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  render() {
    let user = this.context.user;

    if (!user) return null;

    return (
      <>
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 10,
            paddingTop: "5rem",
            overflow: "auto",
          }}
        >
          <div
            className="w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              // this.props.showTnC && !this.state.tncAccepted ? (
              //   this.renderTnC()
              // ) :
              <form
                className="card"
                style={{ width: "60%" }}
                onSubmit={this.onFormSubmit}
              >
                <div className="card-header d-flex justify-content-between">
                  <h6 className="card-title mb-0">
                    {this.state.addTrack.id != null
                      ? "Edit Track"
                      : "Add Track"}
                  </h6>
                  <a
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={this.props.close}
                  >
                    <i className="la la-times"></i>
                  </a>
                </div>
                <div className="card-body">
                  <div action="#">
                    <div className="form-row form-group">
                      <label
                        htmlFor="title"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Song Name
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="form-control"
                          required
                          value={this.state.addTrack.title}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-row form-group">
                      <label
                        htmlFor="description"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          id="description"
                          name="description"
                          className="form-control"
                          required
                          value={this.state.addTrack.description}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-row form-group">
                      <label
                        htmlFor="songImage"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Song Cover Image
                      </label>
                      <div className="col-md-7">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="songImage"
                            name="songImage"
                            accept="image/*"
                            onChange={this.onLoadFile}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="songImage"
                          >
                            {!this.state.addTrack.songImage ||
                            !this.state.addTrack.songImage.name
                              ? "Choose file"
                              : this.state.addTrack.songImage.name}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-row form-group">
                                        <label htmlFor="songFile" className="col-md-4 text-md-right col-form-label">Song File</label>
                                        <div className="col-md-7">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="songFile" name="songFile"
                                                    onChange={this.onLoadFile}
                                                />
                                                <label className="custom-file-label" htmlFor="songFile">
                                                    {!this.state.addTrack.songFile || !this.state.addTrack.songFile.name ? "Choose file" : this.state.addTrack.songFile.name}
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                    {/* <div className="form-row form-group">
                    <label
                      htmlFor="masteredMp3"
                      className="col-md-4 text-md-right col-form-label"
                    >
                      Mastered MP3
                    </label>
                    <div className="col-md-7">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="masteredMp3"
                          name="masteredMp3"
                          onChange={this.onLoadFile}
                          accept=".mp3"
                        />
                        <label className="custom-file-label" htmlFor="masteredMp3">
                          {!this.state.addTrack.masteredMp3 ||
                          !this.state.addTrack.masteredMp3.name
                            ? "Choose file"
                            : this.state.addTrack.masteredMp3.name}
                        </label>
                      </div>
                    </div>
                  </div> */}
                    {Object.keys(DOWNLOAD_TYPES).map((downloadTypeKey) => (
                      <div
                        className="form-row form-group"
                        key={downloadTypeKey}
                      >
                        <label
                          htmlFor={downloadTypeKey}
                          className="col-md-4 text-md-right col-form-label"
                        >
                          {DOWNLOAD_TYPES[downloadTypeKey].title}
                        </label>
                        <div className="col-md-7">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id={downloadTypeKey}
                              name={downloadTypeKey}
                              onChange={this.onLoadFile}
                              accept={DOWNLOAD_TYPES[downloadTypeKey].accept}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor={downloadTypeKey}
                            >
                              {!this.state.addTrack[downloadTypeKey] ||
                              !this.state.addTrack[downloadTypeKey].name
                                ? "Choose file"
                                : this.state.addTrack[downloadTypeKey].name}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="form-row form-group">
                      <label
                        htmlFor="genreId"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Genre
                      </label>
                      <div className="col-md-7">
                        {/* <input type="number" id="genreId" name="genreId" className="form-control" min="0" required
                                                value={this.state.addTrack.genreId}
                                                onChange={this.onInputChange}
                                            /> */}
                        <select
                          id="genreId"
                          name="genreId"
                          className="form-control"
                          required
                          value={this.state.addTrack.genreId}
                          onChange={this.onInputChange}
                        >
                          <option key="default" value="default" disabled={true}>
                            --Select Genre--
                          </option>
                          {this.props.genres.map((genre) => (
                            <option key={genre.id} value={genre.id}>
                              {genre.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-row form-group">
                      <label
                        htmlFor="bpm"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Beats Per Minute
                      </label>
                      <div className="col-md-7">
                        <input
                          type="number"
                          id="bpm"
                          name="bpm"
                          className="form-control"
                          min="30"
                          max="600"
                          required
                          value={this.state.addTrack.bpm}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                    {/* <div className="form-row form-group">
                                        <label htmlFor="tags" className="col-md-4 text-md-right col-form-label">Tags</label>
                                        <div className="col-md-7">
                                            <input type="text" id="tags" name="tags" className="form-control" data-role="tagsinput" min="30" max="600"
                                                value={this.state.addTrack.tags}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div> */}

                    <div className="form-row form-group">
                      <label
                        htmlFor="tags"
                        className="col-md-4 text-md-right col-form-label"
                      >
                        Tags
                      </label>
                      <div className="col-md-7">
                        <TagsInput
                          id="tags"
                          name="tags"
                          className="form-control h-auto"
                          style={{ height: "auto" }}
                          value={this.state.tagsList}
                          onChange={(tagsList) => {
                            this.setState({ tagsList });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-row form-group">
                      <div className="col-md-4"></div>
                      <div className="col-md-7">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="free"
                            name="eventType"
                            className="custom-control-input"
                            required
                            value="free"
                            onChange={this.onInputChange}
                            checked={this.state.addTrack.eventType === "free"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="free"
                          >
                            Free Song
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="paid"
                            name="eventType"
                            className="custom-control-input"
                            value="paid"
                            onChange={this.onInputChange}
                            checked={this.state.addTrack.eventType === "paid"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="paid"
                          >
                            Paid Song
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.state.addTrack.eventType === "paid" && (
                      <div className="form-row form-group">
                        <label
                          fohtmlForr="songPrice"
                          className="col-md-4 text-md-right col-form-label"
                        >
                          Song Price (USD)
                        </label>
                        <div className="col-md-7">
                          <input
                            type="number"
                            id="songPrice"
                            name="songPrice"
                            className="form-control"
                            min="0"
                            value={this.state.addTrack.songPrice}
                            onChange={this.onInputChange}
                          />
                          <span className="form-text text-muted">
                            Please add Song Price if Song is paid
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-7">
                      <button type="submit" className="btn btn-brand btn-air">
                        {this.state.addTrack.id != null
                          ? "Update Track"
                          : "Add Track"}
                      </button>
                      {/* <button type="button" className="btn btn-outline-secondary">Clear Form</button> */}
                    </div>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
      </>
    );
  }
}

EditTrack.contextType = UserContext;
