import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import EditTrack from "./EditTrack";
import { setSubState, retryCall } from "../../common/common";

export default class MyTracks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myTracks: [],
      myTracksInfo: {},
      genres: [],
      selectedTrack: null,
      showEditTrack: false,
    };
  }

  componentDidMount() {
    this.props.setPlayerVisibility(false);

    // Only fetch once when website is openend instead of everytime genre is loaded
    retryCall(this.getMyTracks, 3, 2000);
    retryCall(this.getGenres, 3, 2000);
  }

  getMyTracks = async () => {
    try {
      var response = await requestWithBearer(`/tracks-service/api/myTracks`, {
        method: "get",
        headers: { "Content-Type": "application/json" },
        // body: newTrackData,
      });

      var requestData = response.data;
      console.log(requestData);

      var data = {};
      if (requestData) {
        data.myTracksInfo = requestData;
        if (requestData.content) data.myTracks = [...requestData.content];
      }
      this.setState(data);

      return true;
    } catch (error) {
      console.log("Unable to get myTracks");
      console.log(error);
      return false;
    }
  };

  getGenres = async () => {
    try {
      var uploadResponse = await requestWithoutBearer(
        "/tracks-service/api/genres",
        {
          method: "get",
        }
      );
      console.log(uploadResponse.data);

      if (uploadResponse.data.length > 0) {
        // should we not check for null instead of length?
        this.setState({ genres: uploadResponse.data });
      } else {
        this.setState({ genres: [] });
      }

      return true;
    } catch (error) {
      console.log("Unable to get genres");
      console.log(error);
      return false;
    }
  };

  onInputChange = (event) => {
    // if (event)
    //     event.preventDefault();
    let value = event.target.value;
    // this.setState({ [event.target.name]: value });
    setSubState("addTrack", { [event.target.name]: value });
  };

  showEditTrack = (track) => {
    this.setState({ selectedTrack: track, showEditTrack: true });
  };

  hideEditTrack = () => {
    this.setState({ showEditTrack: false });
  };

  submitTrack = async (track) => {
    this.props.setLoader(true);

    try {
      var response = await requestWithBearer(
        `/tracks-service/api/track/${track.id}/submit`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          // body: userData,
        }
      );

      var requestData = response.data;
      console.log(requestData);

      alert("Track submitted!");
    } catch (error) {
      console.log(error);
      alert("Unable to submit track");
    }

    await this.getMyTracks();

    this.props.setLoader(false);
  };

  deleteTrack = async (track) => {
    this.props.setLoader(true);

    try {
      var response = await requestWithBearer(
        `/tracks-service/api/track/${track.id}`,
        {
          method: "delete",
          headers: { "Content-Type": "application/json" },
          // body: userData,
        }
      );

      var requestData = response.data;
      console.log(requestData);

      alert("Track deleted!");
    } catch (error) {
      console.log(error);
      alert("Unable to delete track");
    }

    await this.getMyTracks();

    this.props.setLoader(false);
  };

  render() {
    let user = this.context.user;

    if (!user) return null;

    return (
      <>
        <div className="banner bg-mytracks"></div>

        <div className="main-container" id="appRoute">
          <button
            className="btn btn-primary"
            onClick={() => this.showEditTrack()}
          >
            Add Track
          </button>
          <div className="row align-items-end">
            <span className="col-6 font-weight-bold">
              {this.state.myTracksInfo.totalElements} Results
            </span>
            <div className="col-6 ml-auto">
              <form
                action="#"
                className="form-inline justify-content-end invisible"
              >
                <label className="mr-2" htmlFor="filter2">
                  Filter By:
                </label>
                <select className="custom-select mr-sm-2" id="filter2">
                  <option value="1" selected>
                    All
                  </option>
                  <option value="2">Drafts</option>
                  <option value="3">Pending</option>
                  <option value="4">Approved</option>
                  <option value="5">Rejected</option>
                  <option value="6">Sold</option>
                </select>
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="section custom-list">
            {this.state.myTracks.map((myTrack) => (
              <div className="custom-list--item" key={myTrack.id}>
                <div
                  className="text-dark custom-card--inline"
                  onClick={() => {
                    if (myTrack.status !== "SOLD") this.showEditTrack(myTrack);
                  }}
                >
                  <div className="custom-card--inline-img">
                    <img
                      src={myTrack.imageUrl}
                      alt=""
                      className="card-img--radius-sm"
                    />
                  </div>

                  <div className="custom-card--inline-desc">
                    <p className="text-truncate mb-0">{myTrack.title}</p>
                    <p className="text-truncate text-muted font-sm mb-0">
                      {myTrack.description}
                    </p>
                  </div>
                </div>
                <div>
                  {myTrack.status === "DRAFT" && (
                    <p className="badge badge-pill badge-secondary">Draft</p>
                  )}
                  {myTrack.status === "APPROVAL_PENDING" && (
                    <p className="badge badge-pill badge-warning">
                      Approval Pending
                    </p>
                  )}
                  {myTrack.status === "APPROVED" && (
                    <p className="badge badge-pill badge-primary">Approved</p>
                  )}
                  {myTrack.status === "REJECTED" && (
                    <p className="badge badge-pill badge-danger">Rejected</p>
                  )}
                  {myTrack.status === "SOLD" && (
                    <p className="badge badge-pill badge-success">Sold</p>
                  )}
                </div>
                <ul className="custom-card--labels d-flex ml-auto">
                  {
                    // myTrack.status !== "SOLD" &&
                    // <li>
                    //     <a className="badge badge-pill badge-info text-white" onClick={() => this.showEditTrack(myTrack)}><i className="la la-edit"></i></a>
                    // </li>
                  }
                  {myTrack.status === "DRAFT" && (
                    <li>
                      <a
                        className="badge badge-pill badge-success text-white"
                        onClick={() => this.submitTrack(myTrack)}
                      >
                        <i className="la la-check"></i>
                      </a>
                    </li>
                  )}
                  {myTrack.status !== "SOLD" && (
                    <li>
                      <a
                        className="badge badge-pill badge-danger text-white"
                        onClick={() => this.deleteTrack(myTrack)}
                      >
                        <i className="la la-trash"></i>
                      </a>
                    </li>
                  )}
                  {/* <li>05:03</li> */}
                  {/* <li className="dropleft">
                                        <a href="#" className="btn btn-icon-only p-0 w-auto h-auto"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="la la-ellipsis-h"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item">
                                                <a href="#" className="dropdown-link favorite">
                                                    <i className="la la-heart-o"></i>
                                                    <span>Favorite</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-item">
                                                <a href="#" className="dropdown-link">
                                                    <i className="la la-plus"></i>
                                                    <span>Add to Playlist</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-item">
                                                <a href="#" className="dropdown-link">
                                                    <i className="la la-download"></i>
                                                    <span>Download</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-item">
                                                <a href="#" className="dropdown-link">
                                                    <i className="la la-share-alt"></i>
                                                    <span>Share</span>
                                                </a>
                                            </li>
                                            <li className="dropdown-item">
                                                <a href="song-details.html" className="dropdown-link">
                                                    <i className="la la-info-circle"></i>
                                                    <span>Song Info</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li> */}
                </ul>
              </div>
            ))}
          </div>

          {this.state.showEditTrack && (
            <EditTrack
              trackDetails={this.state.selectedTrack}
              genres={this.state.genres}
              refreshTracks={this.getMyTracks}
              setLoader={this.props.setLoader}
              close={this.hideEditTrack}
            //   showTnC={this.state.myTracks.length === 0}
            />
          )}
        </div>
      </>
    );
  }
}

MyTracks.contextType = UserContext;
