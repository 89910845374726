export const ACTION_TYPES = {
  SET_PRODUCER: "SET_PRODUCER",
  SET_SONGS: "SET_SONGS",
  SET_SONGS_PAGE: "SET_SONGS_PAGE",
  SET_TOTAL_SONGS_PAGES: "SET_TOTAL_SONGS_PAGES",
  SET_SONGS_SORTING: "SET_SONGS_SORTING",
  SET_GENRES: "SET_GENRES",
  SET_SELECTED_GENRE_PAGE: "SET_SELECTED_GENRE_PAGE",
  SET_CART: "SET_CART",
  ADD_LOADING_CART: "ADD_LOADING_CART",
  REMOVE_LOADING_CART: "REMOVE_LOADING_CART",
  SET_FAVORITES: "SET_FAVORITES",
  ADD_LOADING_FAVORITES: "ADD_LOADING_FAVORITES",
  REMOVE_LOADING_FAVORITES: "REMOVE_LOADING_FAVORITES",
  SET_LATEST_SONGS: "SET_LATEST_SONGS",
  SET_POPULAR_SONGS: "SET_POPULAR_SONGS",
  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  SET_SEARCH_TYPE: "SET_SEARCH_TYPE",
  SET_SELECTED_GENRE: "SET_SELECTED_GENRE",
  SET_SIDEBAR_OPEN: "SET_SIDEBAR_OPEN",
};
