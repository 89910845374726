import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            console.log("Scrolled To Top");
            document.getElementById("wrapper").scrollTop = 0;
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
