// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";

import axios from "axios";

const PROFILES = { staging: "staging", production: "production" };
const profile = PROFILES.staging;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {};
let backendURL = "";

if (profile === PROFILES.staging) {
  firebaseConfig = {
    apiKey: "AIzaSyBj0DuBfd1dW8QFWUW71a7XcxTrMlKF8e8",
    authDomain: "musicafresh-staging.firebaseapp.com",
    projectId: "musicafresh-staging",
    storageBucket: "musicafresh-staging.appspot.com",
    messagingSenderId: "932848562997",
    appId: "1:932848562997:web:60eb23805ab90fedbc127c",
    measurementId: "G-GP49Q0GZ7H",
  };
  backendURL = "https://musicafresh-staging.web.app";
} else if (profile === PROFILES.production) {
  firebaseConfig = {
    apiKey: "AIzaSyCKed3M39xIM8X_Etvv9MiYWbCRLWQ48P0",
    authDomain: "musicafresh-production.firebaseapp.com",
    projectId: "musicafresh-production",
    storageBucket: "musicafresh-production.appspot.com",
    messagingSenderId: "1088620799512",
    appId: "1:1088620799512:web:1b7a7ae0b3e0fb9cba056d",
    measurementId: "G-45WZL12KDH",
  };
  backendURL = "https://musicafresh-production.web.app";
}

// Configure FirebaseUI.
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/signedIn",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      console.log(authResult);

      // Save accessToken locally on first login to fetch facebook profile image. Auto-relogin on refresh does not provide accessToken again.
      if (
        authResult.credential &&
        authResult.credential.providerId === "facebook.com"
      )
        localStorage.setItem("accessToken", authResult.credential.accessToken);
      else localStorage.removeItem("accessToken");

      return false;
    },
  },
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

window.firebaseInstance = firebase;
export const firebaseApp = firebase;
export const auth = firebase.auth();
// export const firestore = firebase.firestore();
// export const realDB = firebase.database();
// export const analytics = firebase.analytics();
// export const storage = firebase.storage();

export const signIn = () => {};

export const signOut = (refresh = true) => {
  auth.signOut().then(function () {
    // window.open("https://" + document.domain, "_self");
    if (refresh) {
      window.location.reload();
      window.location.href = "/";
    }
  });
};

export const requestWithBearer = (url, options, params) => {
  const headers = options.headers || {};
  return auth.currentUser.getIdToken(true).then((idToken) => {
    const authResponse = idToken && { Authorization: `Bearer ${idToken}` };

    return axios({
      method: options.method,
      // url: backendURL + url,
      url,
      headers: { ...authResponse, ...headers },
      data: options.body,
      ...params,
    });
  });
};

export const requestWithBearerAndProgress = (url, options) => {
  const headers = options.headers || {};
  return auth.currentUser.getIdToken(true).then((idToken) => {
    const authResponse = idToken && { Authorization: `Bearer ${idToken}` };

    return axios({
      method: options.method,
      // url: backendURL + url,
      url,
      headers: { ...authResponse, ...headers },
      data: options.body,
      onUploadProgress: (progressEvent) => {
        // if (progressEvent.lengthComputable) {
        console.log(progressEvent.loaded + " " + progressEvent.total);
        // }
      },
    });
  });
};

export const requestWithoutBearer = (url, options) => {
  const headers = options.headers || {};

  return axios({
    method: options.method,
    // url: backendURL + url,
    url,
    headers: { ...headers },
    data: options.body,
  });
};

// export const request = async (url, options) => {
//   const headers = options.headers || {};
//   var idToken = await auth.currentUser.getIdToken(true);
//   if (!idToken)
//     return { error: 'Unable to retrive Firebase ID Token' };

//   const authResponse = idToken && { Authorization: `Bearer ${idToken}` };

//   try {
//     var axiosResponse = await axios({
//       method: options.method,
//       // url: backendURL + url,
//       url,
//       headers: { ...authResponse, ...headers },
//       data: options.body,
//     });
//     console.log(axiosResponse);
//     return axiosResponse;
//   }
//   catch (error) {
//     console.log(error);
//     if (error.response) {
//       console.log(error.response.data);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     }
//   }
// }
