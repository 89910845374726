import React from "react";
import { UserContext } from "../../auth/providers";
import {
  requestWithBearer,
  requestWithoutBearer,
} from "../../firebase/Firebase";
import ReactJkMusicPlayer from "react-jinke-music-player";
// import 'react-jinke-music-player/assets/index.css';
// import './MusicPlayer.css';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import {
  addToCart,
  addToFavorite,
  fetchCart,
  fetchFavorites,
  removeFromCart,
  removeFromFavorite,
} from "../../api/TracksApiController";
import ReactPaginate from "react-paginate";
import { DEFAULT_PAGE_SIZE } from "../../api/TracksApiModel";

class GenreSongs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      songsInfo: {},
      // favorites: [],
      // cart: [],
      selectedTrack: null,
      // showEditTrack: false,
      // playingSongs: [],
      pageNumber: 0,
      totalPages: 0,
    };

    this.audioInstance = null;
  }

  componentDidMount() {
    this.getAllTracks(this.state.pageNumber);
  }

  setSubState = (subState, keyVal) => {
    this.setState((prevState) => {
      if (prevState[subState] != null)
        return {
          [subState]: {
            ...prevState[subState],
            ...keyVal,
          },
        };
      else
        return {
          [subState]: {
            ...keyVal,
          },
        };
    });
  };

  onInputChange = (event) => {
    // if (event)
    //     event.preventDefault();
    let value = event.target.value;
    // this.setState({ [event.target.name]: value });
    this.setSubState("addTrack", { [event.target.name]: value });
  };

  getAllTracks = async (pageNumber) => {
    try {
      console.log(this.props.genre.id);
      var response = await requestWithoutBearer(
        `/tracks-service/api/tracks?genreId=${this.props.genre.id}&page=${pageNumber}&size=${DEFAULT_PAGE_SIZE}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
          // body: newTrackData,
        }
      );

      var requestData = response.data;
      console.log(requestData);

      var data = { ...this.state };
      if (requestData) {
        data.songsInfo = requestData;
        if (requestData.content) data.songs = [...requestData.content];
        if (requestData.totalPages) data.totalPages = requestData.totalPages;
      }
      this.setState(data);
    } catch (error) {
      console.log(error);
      alert("Unable to get all tracks");
    }
  };

  favoriteSong = async (track) => {
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isFavorited(track)) {
      await addToFavorite(track);
    } else {
      await removeFromFavorite(track);
    }

    // Refresh favorites list
    await fetchFavorites(true);
  };

  addToCartSong = async (track) => {
    // this.props.setLoader(true);
    if (!this.context.user) {
      alert("You need to sign in first");
      return;
    }

    if (!this.isInCart(track)) {
      await addToCart(track);
    } else {
      await removeFromCart();
    }

    await fetchCart(true);

    // this.props.setLoader(false);
  };

  isFavorited = (song) => {
    // if (!this.props.favorites) return false;

    for (var i = 0; i < this.props.favorites.length; i++) {
      if (this.props.favorites[i].id === song.id) return true;
    }

    return false;
  };

  isInCart = (song) => {
    // if (!this.state.cart) return false;

    for (var i = 0; i < this.props.cart.length; i++) {
      if (this.props.cart[i].id === song.id) return true;
    }

    return false;
  };

  handlePageChange = (event) => {
    this.setState({ pageNumber: event.selected });
    this.getAllTracks(event.selected);
  };

  render() {
    let user = this.context.user;

    return (
      <>
        <div
          className="banner"
          style={{ backgroundImage: `url("${this.props.genre.imageUrl}")` }}
        ></div>

        <div className="main-container" id="appRoute">
          <div className="row align-items-end">
            <a
              href="#"
              className="col-6 font-weight-bold h4"
              onClick={() => this.props.onBack()}
            >
              &lt; {this.props.genre.title}{" "}
            </a>
            <div className="col-6 ml-auto">
              <form action="#" className="form-inline justify-content-end invisible">
                <label className="mr-2" for="filter2">
                  Sorted By:
                </label>
                <select className="custom-select mr-sm-2" id="filter2">
                  <option selected>Popular</option>
                  <option value="1">A-Z</option>
                  <option value="2">Z-A</option>
                </select>
                {/* <h4 className="mb-0 mr-2"><a href="#" data-toggle="modal" data-target="#filter"><i className="la la-filter"></i></a></h4> */}
              </form>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>

          <div className="row section">
            {this.state.songs.map((song) => (
              <div className="col-xl-3 col-lg-4 col-sm-6 pb-4" key={song.id}>
                <div className="custom-card">
                  <div className="custom-card--img">
                    <div className="custom-card--info">
                      <ul className="custom-card--labels d-flex">
                        <li>
                          <button
                            className="btn btn-pill btn-air btn-danger btn-icon-only"
                            onClick={() => this.favoriteSong(song)}
                            style={{
                              color: this.isFavorited(song)
                                ? "white"
                                : "#f11717",
                              background: this.isFavorited(song)
                                ? "#f11717"
                                : "white",
                            }}
                          >
                            <i className="la la-heart"></i>
                          </button>
                        </li>
                        {/* style={{ color: this.isFavorited(song) ? "white" : "grey" }}
                                                    style={{ color: this.isInCart(song) ? "white" : "grey" }} */}
                        {/* <li>
                          <button
                            className="btn btn-pill btn-air btn-success btn-icon-only"
                            onClick={() => this.addToCartSong(song)}
                            style={{
                              color: !this.isInCart(song) ? "#00c746" : "white",
                              background: !this.isInCart(song)
                                ? "white"
                                : "#00c746",
                            }}
                          >
                            <i className="ion-ios-cart"></i>
                          </button>
                        </li> */}
                      </ul>
                      {/* <div className="dropdown dropdown-icon">
                                                    <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="ion-md-more"></i>
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link favorite">
                                                                <i className="la la-heart-o"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-plus"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-download"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="#" className="dropdown-link">
                                                                <i className="la la-share-alt"></i>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-item">
                                                            <a href="song-details.html" className="dropdown-link">
                                                                <i className="la la-info-circle"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                    </div>
                    <a
                      href="#"
                      className="external"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.playSong(song);
                      }}
                    >
                      <div
                        alt={song.title}
                        className="card-img--radius-lg"
                        style={{
                          backgroundImage: `url(${song.imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "12.25rem",
                        }}
                      ></div>
                      {/* <img
                        src={song.imageUrl}
                        alt={song.title}
                        className="card-img--radius-lg"
                      /> */}
                    </a>
                  </div>

                  <a
                    // href="song-details.html"
                    className="custom-card--link mt-2 d-flex justify-content-between"
                  >
                    <div>
                      <h6>{song.title}</h6>
                      <p>${song.price?.value}</p>
                    </div>
                    <button
                      className={`btn badge-pill ${
                        this.isInCart(song) ? "btn-light" : "btn-success"
                      }`}
                      onClick={() => this.addToCartSong(song)}
                      // style={{ color: !this.isInCart(song) ? "#00c746" : "white", background: !this.isInCart(song) ? "white" : "#00c746" }}
                      style={{ height: "10%" }}
                    >
                      {/* <i className="la la-shopping-cart"></i> */}
                      <i className="ion-ios-cart"></i>
                      {!this.isInCart(song) ? " Add To Cart" : " In Cart"}
                    </button>
                  </a>
                </div>
              </div>
            ))}

            <div
              style={{
                width: "100%",
                display: this.state.totalPages > 1 ? "flex" : "none",
                justifyContent: "center",
              }}
            >
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={this.state.totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={this.state.pageNumber}
              />
            </div>

            <NotificationContainer />
          </div>
        </div>
      </>
    );
  }
}

GenreSongs.contextType = UserContext;

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    favorites: state.favorites,
  };
};

export default connect(mapStateToProps, null)(GenreSongs);
